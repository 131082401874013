import React, { useEffect, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { Link, useLocation } from 'react-router-dom'
import propTypes from 'prop-types'
import ReactLoading from 'react-loading';

import { useSelector, useDispatch } from 'react-redux'
import { selectAuth, setUserType } from '../../../state/authSlice'

import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Divider, Alert } from 'antd';

import {
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup
  } from "reactstrap";


/**
 * Sign in widget component
 * @author Nadia Mayangputri
 * @param {Object} props 
 */
const SignIn = (props) => { 
    const { submitHandler } = props
    const auth = useSelector(selectAuth)
    const dispatch = useDispatch()
    const location = useLocation()


    useEffect(() => {
        // Make sure user type is set correctly according to the url used to sign in
        if(location.pathname.includes('talent')) {
            dispatch(setUserType('TALENT'))
        } else if(location.pathname.includes('admin')) {
            dispatch(setUserType('PRODUCTION_MANAGER'))
        }
    }, [auth, dispatch])

    
    // Show Error box if error(s) exist
    var err = <></>
    if(auth.error != null) {
        err = <Alert
        message="Error"
        description={auth.error}
        type="error"
        showIcon
        className="mt-3"
      />
    }
  return (
    <>
        <div className="mb-4">
        <h1>Sign In</h1>
            <span className="text-muted">Hello there! Nice to see you again.</span>
        </div>
        
        <Formik
        initialValues={{ email: '', password: '' }}
        validate={values => {
            const errors = {};
            if (!values.email) {
            errors.email = 'Required';
            } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
            errors.email = 'Invalid email address';
            }

            if (!values.password) {
                errors.password = 'Required';
            }
            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {

            if(location.pathname.includes("admin")) {
                submitHandler(values.email, values.password, 'PRODUCTION_MANAGER')
            } else if(location.pathname.includes("talent")) {
                submitHandler(values.email, values.password, 'TALENT')
            }         

            setSubmitting(false)
        }}
        >
        {({ isSubmitting }) => (
            <Form className="mt-3">
            <label>Email</label><br></br>
            <Field
                type="email"
                label="Email"
                name="email"
                id="email">
                {({ field, form: { isSubmitting } }) => (
                    <InputGroup >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <MailOutlined style={{color: "#b5b5b5"}} />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input {...field} disabled={isSubmitting} type="email" placeholder="Email" />
                    </InputGroup>
                )}
            </Field>
            <ErrorMessage name="email" component="div"/>
            <br></br>
            <label>Password</label><br></br>
            <Field
                type="password"
                label="Password"
                name="password"
                id="password">
                {({ field, form: { isSubmitting } }) => (
                    <InputGroup >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <LockOutlined style={{color: "#b5b5b5"}}/>
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input {...field} disabled={isSubmitting} type="password" placeholder="Password"/>
                    </InputGroup>
                )}
            </Field>
        
            <ErrorMessage name="password" component="div" />
            <br></br>
            
            {/* Forgot password */}
            {location.pathname.includes("talent") && (
            <Link to="">
                <span className="text-muted text-xs">Forgot Password?</span>
            </Link>
            )}

            {err}
            <button className="btn full-width purple-btn mt-4 mb-5" type="submit" disabled={isSubmitting} > 
                {isSubmitting ? (
                    <div style={{marginTop: "-15px"}}>
                        <ReactLoading type="bubbles" color="#fff" className="m-auto" width={50} height={30}/>
                    </div>
                ) : <span>Sign In</span>}
            </button>
            </Form>
        )}
        </Formik>
        <Divider />
        {/* Only show this section on talent signin page */}
        {location.pathname.includes("talent") && (
            <div className="spaced-between">
                <span className="text-muted">Don't have an account?</span>
                <Link to="/signup/talent">
                    <span style={{color:'#00bcdf'}}><b>Sign Up</b></span>
                </Link>
            </div>
        )}

        {location.pathname.includes("admin") && (
            <div className="flex-centered">

                {/* <span className="text-muted">Want to sign in as a talent?</span> */}
                <Link to="/signin/talent">
                    <span style={{color:'#00bcdf'}}><b>Sign In as Talent</b></span>
                </Link>
            </div>
        )}
        
      
    </>
  );
};

export default SignIn;

SignIn.propTypes = {
    submitHandler: propTypes.func.isRequired
}