import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import AuthService from "../services/auth.service";

// Dummy auth initial state
// const initialState = {
//   token: "bf006ed2297041744741af707476c71fb695d668",
//   userType: "TALENT",
//   isAuthenticated: true,
//   status: 'idle',
//   error: null
// }

// const initialState = {
//   token: "5e63d1faa89aab257e7a3f3efd1131547698ae58",
//   userType: "PRODUCTION_MANAGER",
//   isAuthenticated: true,
//   status: 'idle',
//   error: null
// }

const initialState = {
  token: sessionStorage.getItem('token'),
  userType: sessionStorage.getItem('userType'),
  // Json.parse is used to ensure value taken from session storage is boolean
  isAuthenticated: JSON.parse(sessionStorage.getItem('authed')),
  status: 'idle',
  error: null
}

export const fetchToken = createAsyncThunk('auth/fetchToken', async (data) => {
  let response = await AuthService.signin(data)
  .then((res) => {

    // Checks if the user type of the account matches the sign in portal
    // This will prevent users from signing in to an unintended app
    if(res.data.user_type !== sessionStorage.getItem('userType')) {
        let type = sessionStorage.getItem('userType').toLowerCase().replaceAll("_", " ")
        sessionStorage.setItem('token', null)
        sessionStorage.setItem('authed', false)

        // Return error, prevent signin
        return(`The account you're trying to sign in with is not a ${type} account`)
    } else {
      // If user type matches, set token in session storage
      sessionStorage.setItem('token', res.data.token)
      sessionStorage.setItem('authed', true)
    }
  })
  .catch((err) => {
    // Return error if error is caught
    let errMsg = ""
    Object.values(err.response.data).forEach((val) => {
      errMsg += val
    })
    sessionStorage.setItem('token', null)
    sessionStorage.setItem('authed', false)
    return errMsg;
  })

  return response;

})


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      console.log('logged out')
      state.isAuthenticated = null
      state.token = null
      sessionStorage.setItem('token', null)
      sessionStorage.setItem('authed', null)
    },
    setUserType: (state, action) => {
      state.userType = action.payload
      sessionStorage.setItem('userType', action.payload)
    }
  },
  extraReducers: {
    [fetchToken.pending]: 
      (state) => {
      state.status = 'loading'
    },
    [fetchToken.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      sessionStorage.setItem('token', null)

    },
    [fetchToken.fulfilled]: (state, action) => {
      // When token fetch is successful action.payload will not return anything
      // action.payload will only be defined if there is an error
      if(action.payload === undefined) {
        state.status = 'succeeded'
        state.token = sessionStorage.token
        state.isAuthenticated = JSON.parse(sessionStorage.authed)
      } 
      // When authentication fails, throw error handle as if action is rejected
      else {
        state.status = 'failed'
        state.error = action.payload
        sessionStorage.setItem('token', null)
      }
    }
  }
});

export default authSlice.reducer

export const { logout, setUserType } = authSlice.actions;

export const selectAuth = (state) => state.auth

  