import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

//Redux store
import { useSelector } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'
import { selectJobApplications } from '../../../state/jobApplicationSlice'

// UI Components Libary
import { Row, Col, Container } from 'reactstrap';
import { Skeleton, Divider, Empty, Breadcrumb, Popconfirm, Modal, notification } from 'antd';

import Header from '../Header/Header'

import BusinessIcon from '@material-ui/icons/Business';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';

import JobPostService from '../../../services/jobPost.service'
import EmpTypeDataService from '../../../services/empType.service'
import CompanyDataService from '../../../services/company.service'

import JobPostForm from '../../admin/AdminJobPosts/JobPostForm';
import JobApplicationForm from '../../talent/Forms/JobApplicationForm';

import { capitalizeFirstLetter } from '../../../constants'


const JobPostPage = (props) => {
    
    const [data, setData] = useState({})
    const [empType, setEmpType] = useState("")
    const [ modalVisible, setModalVisible] = useState(false)

    const auth = useSelector(selectAuth)
    const jobApplications = useSelector(selectJobApplications)

    const location = useLocation()
    const [company, setCompany] = useState("")

    useEffect(() => {
        // Fetch job post data
        JobPostService.get(props.match.params.id, auth.token)
        .then(res => {
            setData(res.data)
            EmpTypeDataService.get(res.data.emp_type_id, auth.token)
            .then(res =>
                setEmpType(res.data.name.replaceAll("_", " "))
            )

            CompanyDataService.get(res.data.company_id, auth.token)
            .then((res) => setCompany(capitalizeFirstLetter(res.data.name)))
            
        })




    }, [auth, setData, setCompany])

    const editJob = async (updatedData) => {
        const res = await JobPostService.update(data.id, updatedData, auth.token, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'content-type': 'multipart/form-data'
            }
        })

        return res
    }

    // Dummy
    var viewsCount = 30
    var applicantsNum = 0

    var statistics = <></>
    var headerButtons = <></>
    var breadcrumb = <></>

    // Alter components based on user type
    if(auth.userType === 'PRODUCTION_MANAGER') {
        statistics = <>
            <span className="text-muted text-uppercase">Statistics</span>
            <Row className="mt-3">
                {/* <Col xs="4" md="12" className="mb-3"><VisibilityIcon style={{color: '#ed5e94', fontSize: '1.5em'}}/> <b>{viewsCount}</b> Views</Col> */}
                <Col xs="6" md="12"><AssignmentTurnedInIcon style={{color: '#ed5e94', fontSize: '1.5em'}} /> <b>{applicantsNum}</b> Applicants</Col>
            </Row>
            <button className="mt-4 btn dark-blue-btn pt-1 pb-1 pr-4 pl-4 rounded">View Applicants</button>
        </>

        headerButtons = <div>
            <Link to={`/job-posts/edit/${props.match.params.id}`}>
                <button className="btn dark-blue-btn py-1 px-4 mr-2">Edit</button>
            </Link>

            <Popconfirm
                placement="bottomRight"
                title="Are you sure you want to close this job?"
                // onConfirm={confirm}
                okText="Yes"
                cancelText="No">
                <button className="btn pink-btn-reversed py-1 px-4">Close Post</button>
            </Popconfirm>
        </ div>

        breadcrumb = 
        <Breadcrumb>
            <Breadcrumb.Item><Link to="/job-posts">Manage Job Listings</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{data.job_title}</Breadcrumb.Item>
        </Breadcrumb>

    } else if(auth.userType === 'TALENT') {
        // Show apply button if talent haven't apply for the job, label as 'APPLIED' if application has been done
        if(jobApplications.ids.includes(data.id)) {
            headerButtons = <div><span className="text-muted text-uppercase">Applied</span></div>
        } else {
            headerButtons = <div><button className="btn pink-btn py-1 px-4" onClick={() => setModalVisible(true)}>Apply</button></div>

        }
        breadcrumb = 
        <Breadcrumb>
            <Breadcrumb.Item><Link to="/opportunities">Opportunities</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{data.job_title}</Breadcrumb.Item>
        </Breadcrumb>
        
    }

    var content = <Container className="content-container mt-4">
        <Row>
            <Col xs='12' md='4' lg='3'>
                <Row>
                    {data.show_company && (
                        <Col xs="4" md="12" className="mb-3"><BusinessIcon style={{color: '#3b5998', fontSize: '1.5em'}}/> <b>{company}</b></Col>
                    )}
                    <Col xs="6" md="12" ><LocationOnIcon style={{color: '#3b5998', fontSize: '1.5em'}} /> <b>{data.city + ", "+ data.country}</b></Col>
                </Row>

                <Divider />

                {statistics}
            </Col>
            <Col xs='12' md='7' >
                <label style={{color: "#3b5998"}}>{empType}</label>
                <br></br>
                <label>Job Description</label>
                <p>{data.description}</p>
                <label>Requirements</label>
                <p>{data.requirements}</p>
            </Col>
        </Row>
        
    </Container>

    // Show edit form as content if user is viewing on edit mode
    if(location.pathname.includes('edit')) {
        headerButtons = <div><button className="btn pink-btn py-1 px-4">Discard</button></div>
        breadcrumb = 
        <Breadcrumb>
            <Breadcrumb.Item><Link to="/job-posts">Manage Job Listings</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to={`/job-posts/${data.id}`}>{data.job_title}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Edit</Breadcrumb.Item>
        </Breadcrumb>
        content = <Container className="content-container mt-4"><JobPostForm data={data} submitHandler={editJob}/></Container>
    }


    return(
        <>
        <div className="bg-illustration">
              <img src={require("../../../assets/background/bg-light.png")}></img>
        </div>
        <div className="pt-5"></div>
        <Header 
            title={data.job_title}
            breadcrumb={breadcrumb}
            extra={headerButtons}/>

        {/* Modal to apply for job */}
        <Modal
          title={<h4>Apply for <b>{data.job_title}</b></h4>}
          centered
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={[<></>]}>
          <JobApplicationForm jobId={data.id} handleClose={setModalVisible}/>
        </Modal>

        {content}
        </>
    )
}

export default JobPostPage;