import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { selectTalent, resetTalentData } from '../../../state/talentSlice'
import { logout, selectAuth } from '../../../state/authSlice'

import propTypes from 'prop-types';

import SearchIcon from '@material-ui/icons/Search';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
  } from "reactstrap";

import './Topbar.css'
import authService from '../../../services/auth.service'


const Topbar = (props) => {
  const dispatch = useDispatch()
  const talent = useSelector(selectTalent)
  const auth = useSelector(selectAuth)
  const history = useHistory() 
  var searchbar, avatar;
  
  if(auth.userType === 'TALENT') {
    avatar = talent.avatar
  } else if(auth.userType === 'PRODUCTION_MANAGER') {
    avatar = require("../../../assets/logo/logo-dark-bg-square.png")
  }

  if(props.isMediumScreen) {

    searchbar = 
      <FormGroup className="searchbar mr-auto m-3" nav>
        <InputGroup className="input-group mb-4">
          <InputGroupAddon addonType="prepend">
            <InputGroupText >
              <SearchIcon />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            className="search-input"
            placeholder="Search App"
            type="text"
          />
        </InputGroup>
      </FormGroup>
    }
      return(
          
          <Nav className="topbar-container align-items-center mr-0">
          {/* {searchbar}   */}

          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" style={{color: "#000"}}/>
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right>
              <DropdownItem>Notification 1</DropdownItem>
              <DropdownItem>Notification 2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>See all</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <div className="avatar avatar-sm rounded-circle" style={{overflow: "hidden", backgroundImage: `url(${avatar})`}}>
                </div>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" id="user-dropdown">
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              {auth.userType === 'TALENT' && <DropdownItem to="/profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>}
              {/* <DropdownItem to="/settings" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-calendar-grid-58" />
                <span>Activity</span>
              </DropdownItem> */}
              <DropdownItem to="/support" tag={Link}>
                <i className="ni ni-send" />
                <span>Contact Support</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={e => {
                e.preventDefault()
                 // Logs out be resetting authentication and any talent data in redux store
                 dispatch(logout())
                //  dispatch(resetTalentData())
                 window.location.href = '/'
                }}>
                <ExitToAppIcon />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      )
    
}

export default Topbar;