import http from "../http-common";

class AuthService {

  signin(data) {
    return http.post("api/signin/", data);
  }

  create(data) {
    return http.post("api/signup/", data);
  }

  changePassword(data, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.patch(`api/password/`, data);
  }

  getAuthDetail(token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(`api/password/`);
  }

  // update(id, data) {
  //   return http.put(`talent/${id}/`, data);
  // }

  // delete(id) {
  //   return http.delete(`talent/${id}/`);
  // }

  

}

export default new AuthService();
