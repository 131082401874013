import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types';

import { Container } from '@material-ui/core';
import SideBanner from './SideBanner'

import './AuthComponents.css'

/**
 * Authentication Page wrapper
 * @author Nadia Mayangputri
 * @param {Object} props 
 */
const AuthPage = (props) => {
    return(
        <div className="d-flex">
            <div className="bg-illustration d-lg-none">
                <img src={require("../../../assets/background/bg-light.png")}></img>
            </div>            
            <div className="sidebanner d-none d-lg-block">
                <SideBanner 
                    bgColor="linear-gradient(230deg,#ff5554, #a24bcf,#4b79cf,#4bc5cf) 0% 0% / 300% 300%"
                    bgOpacity={0.9}
                    // bgColor="#dfdff5"
                    // bgOpacity={1}
                    bgImage={props.bg}
                    heading={props.title}/>
            </div>

            {/* Authentication widget */}
            <div className="auth-container">

                <Container className="p-5">
                    <div className="d-lg-none" style={{margin:"30px auto", width: "100px"}}>
                        <Link to='/signin'>
                            <img
                                alt="Sleigh Group"
                                src={require('../../../assets/logo/SleighLogo-Black.png')}
                                style={{width: "100px"}}
                            />
                        </Link> 
                    </div>
                    
                    {props.children}
                    
                </Container>
            </div>
        </div>
    )
}

export default AuthPage;

AuthPage.propTypes = {
    children: propTypes.element.isRequired
}