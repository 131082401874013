import React, { useState, useEffect } from 'react';
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from 'yup';
import moment from 'moment';
import { Redirect, Link, useLocation } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'
import { fetchTalentJobClassification } from '../../../state/talentJobClassificationSlice'

import './Form.css'

import {
    FormGroup,
    Container
  } from "reactstrap";

import {  } from '@material-ui/core';

  
import { Breadcrumb, notification, Input, Select } from 'antd'
import { WarningFilled } from '@ant-design/icons'


import FormikDateTime from '../../common/FormikDateTime'

import Header from "../../common/Header/Header"

import JobApplicationService from "../../../services/jobApplication.service";




const JobApplicationForm = (props) => {
    const { handleClose, data } = props;
    const auth = useSelector(selectAuth)
    const dispatch = useDispatch()

    return(
      <div className="apply-job-container">

      <Formik
      enableReinitialize
      initialValues={{selectedWork: null, file: null}}
      validationSchema={Yup.object().shape({
        selectedWork: Yup.string().url("Must be a valid url")
        })
      }
      onSubmit={(values,{ setSubmitting }) => {
        let data = new FormData()
        data.append('jobpost_id', props.jobId)
        data.append('uploaded_work', values.selectedWork)

        // Create Job Application data
        JobApplicationService.create(data, auth.token, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'content-type': 'multipart/form-data'
            }
          })
          .then(() => {
            setSubmitting(false);
            dispatch(fetchTalentJobClassification)
            handleClose()
          }) 
          .catch((err) => {
            notification.open({
              message: 'Error',
              description: err.message,
              icon: <WarningFilled style={{ color: '#ed5e94' }} />,
            })
            setSubmitting(false);
          })  
          
        }}>
        { (props, isSubmitting) => (
        <Form>
        
            <Container>
          

            {/* <Container className="content-container"> */}
                <span>The data on your profile will be used in your application.</span><br></br>
                <br></br>
                <span>Please select a work from your portfolio you wish to highlight in this application.</span>
                <FormGroup className="mt-3">
                  <label>Work to highlight</label>
                  <Field
                      type="text"
                      label="Selected Work"
                      name="selectedWork"
                      id="selectedWork">
                      {({ field, form: { isSubmitting } }) => (
                      <Select
                      {...field} disabled={isSubmitting}
                      showSearch
                      placeholder="Select a work to highlight"
                      style={{ width: '100%' }}
                      onChange={(e) => {
                          props.setFieldValue('selectedWork', e)
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().startsWith(input.toLowerCase())
                        }
                      >
                      {/* {countries.map(country => (
                          <Option key={country.id}>{country.name}</Option>
                      ))} */}
                      </Select>
                      )}

                  </Field>
                  <ErrorMessage name="selectedWork" component="span"/>
                </FormGroup>

                <FormGroup>
                  <label className="d-block w-100">Upload file</label>
                  <input id="file" name="file" type="file" accept="video/*,audio/*" onChange={(event) => {
                    props.setFieldValue("file", event.currentTarget.files[0]);
                  }}/>
                  <br></br>
                  <span className="help-text">Accepts all file types</span>
                  <br></br>
                  <ErrorMessage name="file" component="span"/>
                </FormGroup>
              
                <div className="spaced-between" style={{width:"100%"}}>
                    <button
                        type="button"
                        onClick={() => handleClose()}
                        className="btn">
                        Cancel
                    </button>
                    
                    <button
                        type="submit"
                        disabled
                        className="btn purple-btn">
                        Submit Application
                    </button>
                </div>
            {/* </Container> */}
            </Container>
            
          
        </Form>
      )}
      
      </Formik>
      
      </div>
  )
}


export default JobApplicationForm;