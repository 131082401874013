import React, { useState, useEffect } from 'react';
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from 'yup';
import moment from 'moment';
import { Redirect, Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

import { useSelector } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'

import './Form.css'

import {
    FormGroup,
    Row,
    Col,
    Container
  } from "reactstrap";


  
import { Breadcrumb, notification, Input } from 'antd'
import { WarningFilled } from '@ant-design/icons'


import FormikDateTime from '../../common/FormikDateTime'

import Header from "../../common/Header/Header"

import TalentSampleWorksService from "../../../services/talentSampleWorks.service";
import SampleWorks from '../TalentProfile/SampleWorks';


const DATE_FORMAT = 'YYYY-MM-DD'
const FILE_SIZE = 160 * 1024;

// Only accept all audio and video files
const SUPPORTED_FORMATS = [
  "audio/*",
  "video/*"
];

const sampleWorksFormSchema = Yup.object().shape({
  title: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  type: Yup.string()
    .required('Required'),
  file: Yup.mixed()
    .required("A file is required")
    // .test(
    //   "fileSize",
    //   "File too large",
    //   value => value && value.size <= FILE_SIZE)
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value && SUPPORTED_FORMATS.includes(value.type)
    )

});


const SampleWorkForm = (props) => {
    const { handleClose, data } = props;
    const auth = useSelector(selectAuth)

    const [initialValues, setInitialValues] = useState({ 
      title:"",
      type:"",
      url:"",
      file:null
   })

   useEffect(() => {
     if(data != null || data != undefined) {
        var endDate = moment(data.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()
        if(data.is_ongoing) {
          endDate = ""
        }
        setInitialValues({ 
          title: data.title
        })
     } else {
        setInitialValues({ 
          title:"",
          type:"",
          url:"",
          file:null
        })
     }
   }, [data, setInitialValues])

    return(
      <div className="new-sample-work-container">

      <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={sampleWorksFormSchema}
      onSubmit={(values, { setSubmitting }) => {
          console.log("submitting..")
          let sampleWorkData = new FormData();
          sampleWorkData.append('sample_work_url', values.url);
          sampleWorkData.append('sample_work_file', values.file, values.file.name);
         
  
          // If data is not null, this form is used for edit instead
          if(data != null) {
            TalentSampleWorksService.update(data.id, sampleWorkData, auth.token, {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                'content-type': 'multipart/form-data'
              }
            })
            .then(() => {
              setSubmitting(false);
              handleClose()
              window.location.href = '/profile'
            }) 
            .catch((err) => {
              notification.open({
                message: 'Error',
                description: err.message,
                icon: <WarningFilled style={{ color: '#ed5e94' }} />,
              })
            })  
            
          } else {
            // Data is null
            // Create post request to add experience to database
            TalentSampleWorksService.create(sampleWorkData, auth.token, {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                'content-type': 'multipart/form-data'
              }
            })
            .then(() => {
              setSubmitting(false);
              handleClose()
              window.location.href = '/profile'
            }) 
            .catch((err) => {
              notification.open({
                message: 'Error',
                description: err.message,
                icon: <WarningFilled style={{ color: '#ed5e94' }} />,
              })
              setSubmitting(false);

            })  
          }
          
        }}>
        { (props, isSubmitting) => (
        <Form>
        
            <Container>
          

            {/* <Container className="content-container"> */}
                
            
                    <div style={{marginTop: "10px"}}>
                      <FormGroup>
                          <label>Title</label><br></br>
                          <Field
                          type="text"
                          label="Title"
                          name="title">
                          {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Title" />
                          )}
                          </Field>
                          <ErrorMessage name="title" component="span"/>
                      </FormGroup>

                        <Row>
                          <Col xs="5">
                            <Field
                                label="Type"
                                name="type"
                                value="upload"
                                type="radio">

                                {({ field, form: { isSubmitting } }) => (
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                    {...field}
                                    disabled={isSubmitting}
                                    className="custom-control-input"
                                    id="upload"
                                    type="radio"
                                    />
                                    <label className="checkbox-label custom-control-label font-weight-normal" htmlFor="upload">
                                    File Upload
                                    </label>
                                </div>
                                )}

                            </Field>
                            </Col>
                            <Col xs="5">
                            <Field
                                label="Type"
                                name="type"
                                value="url"
                                type="radio">
                                {({ field, form: { isSubmitting } }) => (
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                    {...field}
                                    disabled={isSubmitting}
                                    className="custom-control-input"
                                    id="url"
                                    type="radio"
                                    />
                                    <label className="checkbox-label custom-control-label font-weight-normal" htmlFor="url">
                                    Media URL
                                    </label>
                                </div>
                                )}
                            </Field>
                            </Col>
                
                        </Row>

                      {/* Upload  */}
                      {props.values.type === 'upload' && (
                        <FormGroup>
                          <label className="d-block w-100">Upload file</label>
                          <input id="file" name="file" type="file" accept="video/*,audio/*" onChange={(event) => {
                            props.setFieldValue("file", event.currentTarget.files[0]);
                          }}/>
                          <br></br>
                          <span className="help-text">Accepts video and audio file</span>
                          <br></br>
                          <ErrorMessage name="file" component="span"/>
                        </FormGroup>
                      )}

                      {props.values.type === 'url' && (
                        <FormGroup>
                          <label>Media URL</label><br></br>
                          <Field
                          type="text"
                          label="Media URL"
                          name="url">
                          {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Media URL" />
                          )}
                          </Field>
                          <ErrorMessage name="url" component="span"/>
                          <ReactPlayer
                          className='react-player'
                          url={props.values.url}
                          width='100%'
                          height='100%'
                          />
                      </FormGroup>
                      )}
                      
                      </div>
                
                        
                <div className="spaced-between" style={{width:"100%"}}>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn">
                        Cancel
                    </button>
                    {data != null ? (
                    <button
                        type="submit"
                        className="btn purple-btn">
                        Save Changes
                    </button>) : (
                    <button
                        type="submit"
                        className="btn purple-btn">
                        Add
                    </button>)}
                </div>
            {/* </Container> */}

            </Container>
            
          
        </Form>
      )}
      
      </Formik>
      
      </div>
  )
}


export default SampleWorkForm;