import React, { Component, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux'
import { selectTalent } from '../../../state/talentSlice'
import { selectTalentJobClassification } from '../../../state/talentJobClassificationSlice'
import { selectTalentPastExperience } from '../../../state/talentPastExperienceSlice'
import { selectTalentLanguages } from '../../../state/talentLanguagesSlice'
import { selectTalentLanguagePair } from '../../../state/talentLanguagePairSlice'

import "argon-design-system-react/src/assets/vendor/nucleo/css/nucleo.css";

// UI Components Libary
import { Skeleton, Tabs, Divider, Steps } from 'antd';

import EmptyCard from '../../common/Card/EmptyCard'

// Components
import JobClassificationList from '../../common/JobClassificationList'
import ProfileHeader from './ProfileHeader'
import SampleWorks from './SampleWorks'
import ProjectList from './ProjectList'
import JobClassifications from './JobClassifications'
import StatsCard from '../../common/Card/StatsCard'

import './TalentProfile.css'

const { TabPane } = Tabs;
const { Step } = Steps


/**
 *  Talent profile page - the profile clients and other talents see
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
const TalentProfile = (props) => {
    var id;
    if(props.match === undefined || props.match.params === null) {
        id = props.talentID
    } else {
        id = props.match.params.id
    }

    const dispatch = useDispatch()
    const talent = useSelector(selectTalent)
    const talentJobClassification = useSelector(selectTalentJobClassification)
    const talentExperience = useSelector(selectTalentPastExperience)
    const talentLanguages = useSelector(selectTalentLanguages)
    const talentLanguagePairs = useSelector(selectTalentLanguagePair)


    // const talentStatus = useSelector((state) => state.status)
    // const error = useSelector((state) => state.error)

    const [subName, setSubName] = useState("")
    const [displayName, setDisplayName] = useState("")

    useEffect(() => {
        // Set display name according to is_stage_name_preferred_name value
        var fullName = `${talent.given_name} ${talent.last_name}`
        var stageName = talent.stage_name
        if(talent.is_stage_name_preferred_name) {
            setDisplayName(stageName)
            setSubName(fullName)
        } else {
            setDisplayName(fullName)
            setSubName(stageName)
        }
    }, [talent, setSubName, setDisplayName])



    var jobClassificationList = <div>
        <Skeleton.Button active={true} size='lg' shape='default' />
    </div>

    var detailedJobClassifications = <EmptyCard />

    // Dummy sample works
    var sampleWorks = [
        {
            title: "Chilled Serenity",
            url: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
            date: moment('01/02/2020','DD/MM/YYYY').format('ll'),
            source: "youtube",
            type: "video"
        },
        {
            title: "Studio Ghibli Piano Collection",
            url: "https://www.youtube.com/watch?v=YjohMzHkBqI",
            date: moment('20/07/2019','DD/MM/YYYY').format('ll'),
            source: "youtube",
            type: "video"
        },
        {
            title: "Accelerated",
            url: "https://soundcloud.com/miami-nights-1984/accelerated",
            date: moment('11/10/2019','DD/MM/YYYY').format('ll'),
            source: "soundcloud",
            type: "audio"
        },
        {
            title: "Zelda & Chill",
            url: "https://www.youtube.com/watch?v=GdzrrWA8e7A",
            date: moment('22/09/2018','DD/MM/YYYY').format('ll'),
            source: "youtube",
            type: "video"
        },
        {
            title: "Voice Sample",
            url: "http://sleigh-dev.s3.amazonaws.com/media/sample_work/7ed1181c-4a03-45e8-b1a3-8f2f3c57aecc.wav",
            date: moment('22/09/2018','DD/MM/YYYY').format('ll'),
            source: "file",
            type: "audio"
        }
    ]


    if(talentJobClassification.jobClassifications.length > 0) {
        jobClassificationList = <JobClassificationList data={talentJobClassification.jobClassifications} bgColor="#e5e8f0" color="#282f48"/>
    }

    if(talentJobClassification.specialisations.length > 0) {
        detailedJobClassifications = <div>
            {Object.keys(talentJobClassification.specialisations).map((data) => {
                return(<StatsCard 
                        title={data}
                        specialisation={<JobClassificationList data={data} bgColor="#00bcdf" color="#fff" />}
                    />       
                )
            })}
        </div>
    }
    return(
        <div className="profile-container tab-light-bg">
            {/* <div className="bg-illustration">
                <img src={require("../../../assets/background/bg-blue.png")}></img>
            </div> */}

            
            
            <ProfileHeader 
                avatar={talent.avatar}
                displayName={displayName} 
                subName={subName} 
                headline={talent.headline}
                email= {talent.email}
                mobile={talent.phone_number}
                connections={22}
                projects={30}
                courses={3}
                jobs={talentJobClassification.jobClassifications}
            />
        
            <div className="tab-container">

                <Tabs defaultActiveKey="1" className="tab-content" tabBarGutter={70} centered>
                    <TabPane tab="OVERVIEW" key="1">
                        {/* <Steps size="small" >
                            <Step title="Basic Information" status="finish"/>
                            <Step title="Add your skills" status="process"/>
                            <Step title="Add your past project" status="process"/>
                            <Step title="Upload a sample of your work"status="process"/>
                        </Steps> */}
                        {/* <SampleWorks playlist={sampleWorks} showAdd={false}/> */}
                        
                        {/* <Divider /> */}
                        <ProjectList title="Career Highlights" projects={talentExperience.highlightedExperience}/>
                        <Divider />
                        {/* <ProjectList title="Recent Projects" projects={this.talent.projects}/>
                        <Divider /> */}
                        <JobClassifications 
                            data={talentJobClassification.specialisations} 
                            languagePairs={talentLanguagePairs}
                            languages={talentLanguages}
                            showAdd={true}/>
                        {/* <pre>{JSON.stringify(this.talent, null, 2)}</pre> */}
                    </TabPane>
                    <TabPane tab="EXPERIENCE" key="2">
                        <ProjectList title="Past Experience" projects={talentExperience.pastExperience}/>
                    </TabPane>
                    <TabPane tab="SKILLS" key="3">
                        <JobClassifications 
                            data={talentJobClassification.specialisations} 
                            languagePairs={talentLanguagePairs}
                            languages={talentLanguages}
                            showAdd={true}/>      
                    </TabPane>
                    <TabPane tab="PORTFOLIO" key="4">
                        {/* <SampleWorks playlist={sampleWorks} showAdd={true}/> */}
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}


export default TalentProfile;