import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
// import { selectAllTalents, fetchAllTalents } from '../../../state/commonSlice'

// reactstrap components
import {
    Container
} from "reactstrap"

// icons
import AddIcon from '@material-ui/icons/Add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// styles
import "argon-design-system-react/src/assets/scss/argon-design-system-react.scss";
import 'antd/dist/antd.css';
import "./TalentList.css"

import { Card, Avatar } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';

import TalentCard from "../../common/Card/TalentCard/TalentCard"

import { dummyTalents } from '../../../constants'

const { Meta } = Card;


/**
 *  TalentList component - List of talent cards
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
const TalentList = () => {
      // const dispatch = useDispatch()
      const talents = dummyTalents 
      console.log(talents)

      // const talentStatus = useSelector((state) => state.status)
      // const error = useSelector((state) => state.error)

      let content;

      // useEffect(() => {
        // if (talentStatus === 'idle') {
        //   dispatch(fetchAllTalents())
        // }
      // }, [talentStatus, dispatch])

      // if (talentStatus === 'loading') {
      //   content = <div className="loader">Loading...</div>
      // } else if (talentStatus === 'succeeded') {
      //   content = <>
      //       {talents.map(talent => {
                   
      //              if(talent !== undefined) {

      //                  return(
      //                      <TalentCard 
      //                          key={talent.id} 
      //                          identifier={talent.id} 
      //                          name={talent.given_name + " " + talent.surname} 
      //                          text={talent.headline}
      //                          btnDisplay="flex"
      //                          birthdate={talent.birthdate} 
      //                          img={talent.avatar}
      //                          imgSize="150px"
      //                          imgMargin="-50px"
      //                          margin="40px"
      //                          showProfileLink={true}/>
      //                  )
      //              }
      //          })}
        
      //   </>
        
      // } else if (talentStatus === 'error') {
      //   content = <div>{error}</div>
      // }
    

      return (
        
        // var jobNames = this.props.jobClassifications.map((jobClassification) => {
        //     return(jobClassification.name)
        // })
              <div id="talent-list-container">
              {/* <Header title="Browse Talents" btnTitle="New Talent" btnLink="/new-talent" btnIcon={<AddIcon style={{fontSize: "0.8em"}} />}/> */}
              {/* <JobClassificationList data={jobNames} bgColor="#cb1670" color="#fff"/> */}
              <Container id="talent-list">
              {talents.map(talent => {
                   var name = talent.given_name + " " + talent.last_name;
                   if(talent.is_stage_name_preferred_name) {
                     name = talent.stage_name
                   }
                   if(talent != undefined) {

                       return(
                          //  <TalentCard 
                          //      key={talent.id} 
                          //      identifier={talent.id} 
                          //      name={talent.given_name + " " + talent.surname} 
                          //      text={talent.headline}
                          //      btnDisplay="flex"
                          //      birthdate={talent.birthdate} 
                          //      img={talent.avatar}
                          //      imgSize="150px"
                          //      imgMargin="-50px"
                          //      margin="40px"
                          //      showProfileLink={true}/>
                          <Card
                          style={{ width: 400 }}
                          // cover={
                          //   <img
                          //     alt="example"
                          //     src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                          //   />
                          // }
                          actions={[
                            <SettingOutlined key="setting" />,
                            <EditOutlined key="edit" />,
                          ]}
                        >
                          <Meta
                            avatar={<Avatar src={talent.avatar} size={70}/>}
                            title={name}
                            description={
                              <span className="text-muted">{talent.headline}</span>
                            }
                          />
                        </Card>
                       )
                   }
               })}
        
              </Container>
              </div>
          
      )
}

export default TalentList;