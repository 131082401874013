import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Lottie from 'react-lottie'
import animationData from '../../../assets/lotties/34802-group-meeting-animation.json'
import Typing from 'react-typing-animation';

import { Container } from '@material-ui/core';

import './AuthComponents.css'

const SideBanner = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return(
        <div className="sidebanner-container" style={{backgroundImage:`url(${require(`../../../assets/${props.bgImage}`)})`}}>
        {/* <div className="sidebanner-container"> */}
            <div className="overlay" style={{background: props.bgColor, opacity: props.bgOpacity}}>
                <Container className="auth-form p-5">
                    <div style={{width: "100px"}}>
                        <Link to='/signin'>
                            <img
                            alt="Sleigh Group"
                            src={require(`../../../assets/logo/SleighLogo-White.png`)}
                            style={{width: "100px"}}
                            />
                        </Link>
                    </div>
                    <br></br>
                    <div className="mt-2 mt-md-5">
                    {/* <Typing speed={60} startDelay={1000} hideCursor>
                        <h1>{props.heading}</h1>
                    </Typing> */}
                    <div className="banner-heading text-white">{props.heading}</div>

                    </div>
                    {props.extra}
                    {/* <h1>{props.heading}</h1>
                    <Lottie options={defaultOptions}
                        height={500}
                        width={500}
                    />

                   <span style={{position: 'absolute', bottom: '30px', left: '30px'}} className="text-muted text-xs">Art and animation by <a className="text-muted" href="https://lottiefiles.com/irfanmunawar">Irfan Munawar</a></span> */}

                <span style={{position: 'absolute', bottom: '30px', left: '30px'}} className="text-muted text-xs">{props.note}</span>

                </Container>
            </div>
            
        </div>
    )
}

export default SideBanner;