
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment';

import { useSelector } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'

import TalentPastExperienceService from '../../../services/talentPastExperience.service'

import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    Container
} from "reactstrap";

import { Empty, Modal, Dropdown, Menu, Popconfirm, notification} from 'antd'
import { EditOutlined, MoreOutlined, DeleteFilled, DeleteOutlined, WarningFilled, StarFilled } from '@ant-design/icons'

import StarIcon from '@material-ui/icons/Star';
import BusinessIcon from '@material-ui/icons/Business';
import AddIcon from '@material-ui/icons/Add';

import ProjectForm from '../Forms/ProjectForm'

import './TalentProfile.css'

/**
 * Project (Past Experience) Card Component
 * @param {*} props 
 */
const ProjectCard = (props) => {
    const { project, openModal, setData } = props
    const auth = useSelector(selectAuth)

    const deleteExperience = () => {
        TalentPastExperienceService.delete(project.id, auth.token)
        .then(() => window.location.href = '/profile')
        .catch((err) => {
            notification.open({
                message: 'Error',
                description: err.message,
                icon: <WarningFilled style={{ color: '#ed5e94' }} />,
            });
        })
    }

    const menu = (
        <Menu style={{ width: 100 }}>
          <Menu.Item 
            icon={<EditOutlined />} 
            onClick={() => {
                setData(project)
                openModal(true)
            }}>
              <span style={{fontSize: "0.9em"}}>Edit</span>     
          </Menu.Item>
          <Popconfirm 
            placement="right" 
            title="Are you sure you want to delete this experience?" 
            onConfirm={deleteExperience} 
            okText="Yes" 
            cancelText="No"></Popconfirm>
          <Menu.Item icon={<DeleteOutlined />}>   
            <Popconfirm 
            placement="right" 
            title="Are you sure you want to delete this experience?" 
            onConfirm={deleteExperience} 
            okText="Yes" 
            cancelText="No">
                <span style={{fontSize: "0.9em"}}>Delete</span>
            </Popconfirm>     
          </Menu.Item>
        </Menu>
      );

    return(
        <div className="project-card">
            <Card className="card-stats">
                <CardBody>
                <Row>
                    <Col>
                        <CardTitle className="text-uppercase text-muted mb-0">
                            {project.role}
                        </CardTitle>
                        <div><span className="h5 font-weight-bold mb-0">{project.nature_of_project}</span></div>
                        <span className="h2 font-weight-bold mb-0">{project.project_title}</span>
                    </Col>
                    <Col className="col-auto">
                        {project.icon}
                    </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="mr-2">
                    <BusinessIcon />   {project.company_name}
                    </span>
                </p>
                <div className="spaced-between">

                    <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap">{moment(project.start_date).format('ll')} - {project.endDate}</span>
                    </p> 
                    {/* <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                        <a><MoreOutlined style={{fontSize:"1.5em"}}/></a>
                    </Dropdown> */}
                    <div className="mt-3 mr-2">
                        <EditOutlined 
                        style={{color: "#8898aa", marginRight: "20px"}}
                        onClick={() => {
                            setData(project)
                            openModal(true)
                        }}/>  
                        <Popconfirm 
                        placement="right" 
                        title="Are you sure you want to delete this experience?" 
                        onConfirm={deleteExperience} 
                        okText="Yes" 
                        cancelText="No">
                            <DeleteOutlined
                            style={{color: "#ed5e94"}}/>  
                        </Popconfirm>     
                    </div>
                    
                </div>
                
                </CardBody>
            </Card>
        </div>
    )

}

const ProjectList = (props) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null)

    const closeModal = () => {
        setModalVisible(false)
        setSelectedProject(null)
    }

    return(
        <Container>
            {/* Modal to add past experience*/}
            <Modal
            title={<h4>Experience</h4>}
            centered
            style={{minWidth:"60%"}}
            visible={modalVisible}
            onCancel={closeModal}
            footer={[<></>]}>
                <ProjectForm handleClose={closeModal} data={selectedProject}/>
            </Modal>

            <div className="spaced-between">
                <h2>{props.title}</h2>
                {/* <Link to="/profile/add-project" className="d-md-none">
                    <button className="btn btn-circle m-0 pink-btn"><AddIcon /> </button>
                </Link> */}
                {props.title !== "Career Highlights" && (
                    <div>
                        <button className="btn btn-circle m-0 purple-btn" onClick={() => setModalVisible(true)}><AddIcon /> </button>
                    </div>
                )}
                
            </div>

            {props.projects.length > 0 ? (
                <div className="grid-container mt-3">
                    {props.projects.map((project, index) => {
                        let endDate = moment(project.end_date).format('ll');
                        let icon = <></>
                        if(project.is_career_highlight) {
                            icon = 
                            <div className="icon icon-shape text-white rounded-circle shadow" style={{backgroundColor: "#fcc203", width:'35px', height: '35px'}}>
                                <StarIcon style={{color: "rgba(255, 255, 255, 1)", fontSize: "24px"}}/>
                                {/* <StarFilled style={{color: "rgba(255, 255, 255, 1)", fontSize: "12px"}}/> */}

                            </div>
                        }
                        if(project.is_ongoing) {
                            endDate = "Present"
                        }
                        return(
                        <div key={index}>
                            <ProjectCard project={{...project, endDate, icon}} setData={setSelectedProject} openModal={setModalVisible}/>
                        </div>)
                    })}
                </div>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`You haven't added any ${props.title.toLowerCase()} yet.`}/>
            )}

            
        </Container>
    )
    
}

export default ProjectList;

ProjectList.propTypes = {
    title: propTypes.array,
    projects: propTypes.array,
}

ProjectList.defaultProps = {
    title: "Past Experience",
    projects: [],
}