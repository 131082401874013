import http from "../http-common";

const url = "api/talent/sample-work/"

class TalentSampleWorksService {
  getAll(token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(url);
  }

  get(id, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(`${url + id}/`);
  }
  
  create(data, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.post(url, data);
  }

  update(id, data, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.put(`${url + id}/`, data);
  }

  delete(id, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.delete(`${url + id}/`);
  }

}

export default new TalentSampleWorksService();
