import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, useHistory, Switch } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { fetchTalent, resetTalentData, selectTalent } from './state/talentSlice'
import { fetchToken, logout, selectAuth, setUserType } from './state/authSlice'
import { fetchAllTalents } from './state/coreSlices'

import { fetchAllJobPosts } from './state/jobPostSlice'

import { 
  fetchAllJobClassifications,
  fetchAllLanguages, 
  fetchAllLanguageProficiencies,
  fetchAllProjectTypes,
  fetchAllProjectSubtypes,
  fetchAllEmpTypes
} from './state/coreSlices'

import { fetchAllCompanies } from './state/companySlice'

// UI Components Library
import "argon-design-system-react/src/assets/vendor/nucleo/css/nucleo.css";
import "argon-design-system-react/src/assets/scss/argon-design-system-react.scss";
import "argon-dashboard-react/src/assets/scss/argon-dashboard-react.scss";
import './App.css';


// Navigation Components
import Sidebar from './components/common/Sidebar/Sidebar'
import Topbar from './components/common/Topbar/Topbar'


// Auth Components
import AuthPage from './components/common/AuthComponents/AuthPage'
import SignIn from './components/common/AuthComponents/SignIn'
import SignUp from './components/common/AuthComponents/SignUp'

import TalentApp from './components/talent/TalentApp'
import AdminApp from './components/admin/AdminApp'


import {talentNavItems, adminNavItems, navSubItems, dummyOpportunities, appliedJobs, savedJobs} from './constants'

import AuthService from './services/auth.service'

import ExitToAppIcon from '@material-ui/icons/ExitToApp';


/**
 *  App component
 *  @author Nadia Mayangputri
 */ 
const App = (props) => {
  const history = useHistory();

  const auth = useSelector(selectAuth)
  const dispatch = useDispatch()    


  const handleSignup = (email, password) => {
    let userData = new FormData();
    userData.append('email', email);
    userData.append('password', password);

    // Create new account
    return AuthService.create(userData, {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        'content-type': 'multipart/form-data'
      }
    })

  }

  const handleLogin = (email, password, userType) => {
    let userData = new FormData();
    userData.append('email', email);
    userData.append('password', password);
    dispatch(setUserType(userType.toUpperCase()))
    dispatch(fetchToken(userData))
    
  }


  useEffect(() => {
    if(auth.isAuthenticated) {
      dispatch(fetchAllCompanies(auth.token))
      dispatch(fetchAllJobPosts(auth.token))
      dispatch(fetchAllJobClassifications(auth.token))
      dispatch(fetchAllLanguages(auth.token))
      dispatch(fetchAllLanguageProficiencies(auth.token))
      dispatch(fetchAllProjectTypes(auth.token))
      dispatch(fetchAllProjectSubtypes(auth.token))
      dispatch(fetchAllEmpTypes(auth.token))
    }
  }, [auth])
  

  return (
    <Router>
      <div className="App">

        {auth.isAuthenticated && auth.userType === 'TALENT' && (
          <Sidebar
            routes={talentNavItems}
            navSubItems={navSubItems}   
            userType={auth.userType}      
            logo={{
              innerLink: "/",
              imgSrc: require("./assets/logo/SleighLogo-Black.png"),
              imgAlt: "Sleigh Group"
            }}
          />
        )}

        {auth.isAuthenticated && auth.userType === 'PRODUCTION_MANAGER' && (
          <Sidebar
            routes={adminNavItems}
            navSubItems={navSubItems}      
            userType={auth.userType}        
            logo={{
              innerLink: "/",
              imgSrc: require("./assets/logo/SleighLogo-Black.png"),
              imgAlt: "Sleigh Group"
            }}
          />
        )}

        
          <div className="main-content">
            <div className="d-none d-lg-block">
              {auth.isAuthenticated && (
                <Topbar isMediumScreen={true}/>
              )}
            </div>
            <div>

                {auth.userType === 'TALENT' ? (
                  <TalentApp handleLogin={handleLogin} handleSignup={handleSignup}/>) : (
                  <AdminApp handleLogin={handleLogin} handleSignup={handleSignup}/>
                )}

                <Route exact path="/signin/admin">
                {auth.isAuthenticated ? <Redirect to="/"/> :
                    <AuthPage title={<h1>Hi! Welcome back to the Sleigh Group Production Platform!</h1>} bg="stil-flRm0z3MEoA-unsplash.jpg">
                    <SignIn submitHandler={handleLogin} />
                    </AuthPage>}
                </Route>

                <Route path="/signin/talent">
                {auth.isAuthenticated ? <Redirect to="/"/> :
                    <AuthPage 
                    title={<h1>Hi! Welcome back to the Sleigh Group Talent App!</h1>} 
                    bg="jason-rosewell-ASKeuOZqhYU-unsplash.jpg">
                    <SignIn submitHandler={handleLogin} />
                    </AuthPage>}
                </Route>
                
                <Route path="/signup/talent">
                    {auth.isAuthenticated ? <Redirect to="/"/> :
                    <AuthPage title={<h1>Welcome to the Sleigh Group Talent App!</h1>}  bg="jason-rosewell-ASKeuOZqhYU-unsplash.jpg">
                    <SignUp submitHandler={handleSignup} />
                    </AuthPage>}
                </Route>

            </div>

            
          </div>

          {/* Log out button */}
          {auth.isAuthenticated && (
            <>
            <div 
            style={{position: "fixed", left: "20px", bottom: "30px", width: "100px"}} 
            className="spaced-between d-none d-md-flex"
            onClick={e => {
                e.preventDefault()
                // Logs out be resetting authentication and any talent data in redux store
                dispatch(logout())
                // dispatch(resetTalentData())
                window.location.href = '/'
                }}>
                <i><ExitToAppIcon /></i>
                <div>Logout</div> 
            </div>
            </>
          )}
          
        
      </div>
    </Router>
  );
  
}

export default App;
