import React, { useState } from 'react';
import propTypes from 'prop-types'
import { Field, Form, Formik, ErrorMessage, FieldArray } from "formik";
import * as Yup from 'yup';

import { useSelector, useDispatch } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'
import { fetchTalentJobClassification } from '../../../state/talentSlice' 

import { 
    selectAllJobClassifications, 
    selectAllLanguages, 
    selectAllLanguageProficiencies 
} from '../../../state/coreSlices'


import SpecializationDataService from '../../../services/specialisation.service'
import JobClassificationDataService from '../../../services/jobClassification.service'
import TalentJobClassificationService from '../../../services/talentJobClassification.service'
import TalentLanguageService from '../../../services/talentLanguage.service'
import TalentLanguagePairService from '../../../services/talentLanguagePair.service'

import { getNameById } from '../../../constants'

// reactstrap components
import {
    Row,
    Col,
    Container,
    FormGroup
} from "reactstrap"


import { Modal, Empty, Select, Divider, Skeleton, Popconfirm, notification } from 'antd'

import { DeleteOutlined, WarningFilled } from '@ant-design/icons'

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';


import { capitalizeFirstLetter } from '../../../constants'
import jobClassificationService from '../../../services/jobClassification.service';

const { Option } = Select

const AddJobClassificationForm = (props) => {
    const { handleClose } = props
    const dispatch = useDispatch()

    const jobClassifications = useSelector(selectAllJobClassifications)
    const auth = useSelector(selectAuth)

    const languages = useSelector(selectAllLanguages)
    const languageProficiencies = useSelector(selectAllLanguageProficiencies)
    const [specialisations, setSpecialisations] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [showLanguageSelect, setShowLanguageSelect] = useState(false)
    const [showLanguagePairSelect, setShowLanguagePairSelect] = useState(false)


    /**
     *  Create a group of checkbox field
     * @param {array} arr 
     * @param {string} dataTarget 
     * @param {string} dataName 
     */
    const createCheckboxGroup = (arr, dataTarget, dataName) => {
        return(
          <FieldArray
              name={dataName}
              render={arrayHelpers => (
              
                <div>
                  {arr.map(arrItem => (
                    <div key={arrItem.id} className="custom-control custom-checkbox mb-3">
                       
                        <input
                          className="custom-control-input"
                          name={dataName}
                          type="checkbox"
                          value={arrItem.id}
                          id={arrItem.id+dataName}
                          checked={dataTarget.includes(arrItem.id)}
                          onChange={e => {
                            if (e.target.checked) arrayHelpers.push(arrItem.id);
                            else {
                              const idx = dataTarget.indexOf(arrItem.id);
                              arrayHelpers.remove(idx);
                            }
                          }}
                        />
                      <label className="custom-control-label checkbox-label font-weight-normal" htmlFor={arrItem.id+dataName}>
                        {capitalizeFirstLetter(arrItem.name)}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            />
        )
      }

    /**
     * 
     * @param {number} id 
     */
    const handleJobClassificationChange = (id) => {
        JobClassificationDataService.get(id, auth.token)
        .then((res) => {
            var jobClassName = res.data.name

            // Voice actor and Translator have extra select items, hence update flag
            if(jobClassName === 'VOICE_ACTOR') {
                setShowLanguageSelect(true)
            } else {
                setShowLanguageSelect(false)
            }
    
            if(jobClassName === 'TRANSLATOR') {
                setShowLanguagePairSelect(true)
            } else {
                setShowLanguagePairSelect(false)
            }
        })

        setIsLoading(true)
        // Get specializations of chosen job classification
        SpecializationDataService.findByJobClassification(id, auth.token)
        .then((res) => {
            setSpecialisations(res.data.reverse())
            setIsLoading(false)
        })

        
    }

    return(
    <Formik
          enableReinitialize
          initialValues={{ 
            jobClassification: null,
            specialisations: [],
            languages: [],
            languagePairs: []      
          }}
          validationSchema={
              Yup.object().shape({
              jobClassification: Yup.string().required('Required')
            })}
          onSubmit={(values, { setSubmitting }) => {
            if(values.specialisations.length > 0) {
                // Create a skill detail data on every specialisation
                values.specialisations.forEach(specialisation => {
                    var data = new FormData()
                    data.append('skill_category_id', parseInt(values.jobClassification))
                    data.append('specialization_id', parseInt(specialisation))
                    TalentJobClassificationService.create(data, auth.token, {
                        headers: {
                        // Overwrite Axios's automatically set Content-Type
                        'content-type': 'multipart/form-data'
                        }
                    })
                })
            } else {
                var data = new FormData()
                data.append('skill_category_id', parseInt(values.jobClassification))
                TalentJobClassificationService.create(data, auth.token, {
                    headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'content-type': 'multipart/form-data'
                    }
                })
            }

            // Add Language
            if(values.languages.length > 0) {
                values.languages.forEach(language => {
                  let languagesData = new FormData();
                  languagesData.append('language', language.language);
                  languagesData.append('proficiency', language.proficiency);
                  TalentLanguageService.create(languagesData, auth.token, {
                    headers: {
                      // Overwrite Axios's automatically set Content-Type
                      'content-type': 'multipart/form-data'
                    }
                  })
                })
              }
  
              // Add Language Pair
              if(values.languagePairs.length > 0) {
                values.languagePairs.forEach(languagePair => {
                  let languagePairsData = new FormData();
                  languagePairsData.append('source_language', languagePair.source);
                  languagePairsData.append('target_language', languagePair.target);
                  languagePairsData.append('proficiency', languagePair.proficiency);
                  TalentLanguagePairService.create(languagePairsData, auth.token, {
                    headers: {
                      // Overwrite Axios's automatically set Content-Type
                      'content-type': 'multipart/form-data'
                    }
                  })
                })
              }

            
            setSubmitting(false)
            handleClose(false)
            window.location.href = '/profile'

            }}>
            { (props, isSubmitting) => (
            <Form>
                <FormGroup>
                    <label>Skill</label>
                    <Field
                        type="text"
                        label="Job Classification"
                        name="jobClassification"
                        id="jobClassification">
                        {({ field, form: { isSubmitting } }) => (
                        <Select
                        {...field} disabled={isSubmitting}
                        showSearch
                        placeholder="Skills"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            props.setFieldValue("jobClassification", e)
                            handleJobClassificationChange(e)
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().startsWith(input.toLowerCase())
                            }
                        >
                        {jobClassifications.map(jobClassification => (
                            <Option key={jobClassification.id}>
                                {capitalizeFirstLetter(jobClassification.name.replaceAll('_', ' ').toLowerCase())}
                            </Option>
                        ))}
                        </Select>
                        )}

                    </Field>
                    <ErrorMessage name="jobClassification" component="span" />
                </FormGroup>
                <FormGroup>
                    {specialisations.length > 0 && <label>Specialization</label>}
                    {!isLoading ? (
                        createCheckboxGroup(specialisations, props.values.specialisations, "specialisations")
                    ) : (
                        <>
                        <br></br>
                        <Skeleton.Input className="mt-2 w-100" active size='small'/><br></br>
                        <Skeleton.Input className="mt-2 w-100" active size='small'/><br></br>
                        <Skeleton.Input className="mt-2 w-100" active size='small'/><br></br>
                        <Skeleton.Input className="mt-2 w-100" active size='small'/><br></br>
                        </>
                    )}
                </FormGroup>

                {/* Language select field, only show language select field when voice actor is selected*/}
                {showLanguageSelect && (
                    <FormGroup>
                        <label>Languages</label>
                        <FieldArray
                        name="languages"
                        render={arrayHelpers => (
                            <div>
                            {props.values.languages.map((language, index) => (
                                <div key={index}>
                                    <Row>
                                    <Col>
                                        <Field
                                            type="text"
                                            label="language"
                                            name={`languages.${index}.language`}
                                            id="language">
                                            {({ field, form: { isSubmitting } }) => (
                                            <Select
                                            {...field} disabled={isSubmitting}
                                            showSearch
                                            placeholder="Language"
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                props.setFieldValue(`languages.${index}.language`, e)
                                            }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().startsWith(input.toLowerCase())
                                            }
                                            >
                                            {languages.map(language => (
                                                <Option key={language.id}>{language.name}</Option>
                                            ))}
                                            </Select>
                                            )}

                                        </Field>
                                    </Col>
                                    <Col>
                                    <Field
                                            type="text"
                                            label="language"
                                            name={`languages.${index}.proficiency`}
                                            id="language">
                                            {({ field, form: { isSubmitting } }) => (
                                            <Select
                                            {...field} disabled={isSubmitting}
                                            showSearch
                                            placeholder="Proficiency"
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                props.setFieldValue(`languages.${index}.proficiency`, e)
                                            }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().startsWith(input.toLowerCase())
                                            }
                                            >
                                            {languageProficiencies.map(proficiency => (
                                                <Option key={proficiency.id}>{capitalizeFirstLetter(proficiency.name.toLowerCase())}</Option>
                                            ))}
                                            </Select>
                                            )}

                                        </Field>
                                    </Col>
                                    <Col>
                                        <DeleteIcon 
                                        onClick={() => arrayHelpers.remove(index)}
                                        style={{fontSize: "1.2em", cursor: "pointer"}}/>
                                    </Col>
                                    
                                    </Row>
                                
                                    
                                </div>
                            ))}
                            
                            <button 
                            className="py-1 px-3 rounded blue-btn btn-icon btn-3" 
                            color="neutral" 
                            type="button" 
                            style={{marginTop: "20px"}}
                            onClick={() => arrayHelpers.push({language: null, proficiency: null})}>
                            <span className="btn-inner--icon">
                                <AddIcon style={{fontSize: "0.8em"}}/>
                            </span>
                            <span className="btn-inner--text" style={{fontSize: "0.9em"}}>Language</span>
                            </button>
                        </div>
                        )}
                        />
                    </FormGroup>
                )}

                {/* Language pair select field, only show if translator is selected */}
                {showLanguagePairSelect && (
                    <FormGroup>
                        <span className="subtext">Please specify languages you can translate to/from:</span>
                    
                        <FieldArray
                        name="languagePairs"
                        render={arrayHelpers => (
                            <div>
                            {props.values.languagePairs.map((pair, index) => (
                                <div key={index}>
                                <Row className="mt-2">
                                <Col xs="12" md="3">
                                    <Field
                                        type="text"
                                        label="language"
                                        name={`languagePairs.${index}.source`}
                                        id="language">
                                        {({ field, form: { isSubmitting } }) => (
                                        <Select
                                        {...field} disabled={isSubmitting}
                                        showSearch
                                        placeholder="Source"
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            props.setFieldValue(`languagePairs.${index}.source`, e)
                                        }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().startsWith(input.toLowerCase())
                                        }
                                        >
                                        {languages.map(language => (
                                            <Option key={language.id}>{language.name}</Option>
                                        ))}
                                        </Select>
                                        )}

                                    </Field>
                                </Col>
    
                                <Col xs="12" md="3">
                                    <Field
                                        type="text"
                                        label="language"
                                        name={`languagePairs.${index}.target`}
                                        id="language">
                                        {({ field, form: { isSubmitting } }) => (
                                        <Select
                                        {...field} disabled={isSubmitting}
                                        showSearch
                                        placeholder="Target"
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            props.setFieldValue(`languagePairs.${index}.target`, e)
                                        }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().startsWith(input.toLowerCase())
                                        }
                                        >
                                        {languages.map(language => (
                                            <Option key={language.id}>{language.name}</Option>
                                        ))}
                                        </Select>
                                        )}

                                    </Field>
                                </Col>
                                <Col xs="12" md="4">
                                    <Field
                                        type="text"
                                        label="language"
                                        name={`languagePairs.${index}.proficiency`}
                                        id="language">
                                        {({ field, form: { isSubmitting } }) => (
                                        <Select
                                        {...field} disabled={isSubmitting}
                                        showSearch
                                        placeholder="Proficiency"
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            props.setFieldValue(`languagePairs.${index}.proficiency`, e)
                                        }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().startsWith(input.toLowerCase())
                                        }
                                        >
                                        {languageProficiencies.map(proficiency => (
                                            <Option key={proficiency.id}>{capitalizeFirstLetter(proficiency.name.toLowerCase())}</Option>
                                        ))}
                                        </Select>
                                        )}

                                    </Field>
                                    </Col>
                                <Col xs="12" md="2">
                                <DeleteIcon 
                                onClick={() => arrayHelpers.remove(index)}
                                style={{fontSize: "1.2em", cursor: "pointer"}}/>
                            
                                </Col>
                                </Row>
                                
                                
                                </div>
                            ))}
                            
                            <button 
                                className="py-1 px-3 rounded blue-btn btn-icon btn-3" 
                                color="neutral" 
                                type="button" 
                                style={{marginTop: "20px"}}
                                onClick={() => arrayHelpers.push({ source: null, target: null, proficiency: null })}>
                                <span className="btn-inner--icon">
                                <AddIcon style={{fontSize: "0.8em"}}/>
                                </span>
                                <span className="btn-inner--text" style={{fontSize: "0.9em"}}>Language Pair</span>
                            </button>
                            </div>
                        )}
                        />
                    </FormGroup> 
                )}

                <br></br>
                {/* <pre>{JSON.stringify(props.values, null, 2)}</pre>    */}
                <div className="spaced-between" style={{width:"100%"}}>
                    <button
                        type="button"
                        onClick={() => handleClose(false)}
                        className="btn">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn purple-btn"
                        disabled={props.values.jobClassification === null || isLoading || isSubmitting}>
                        Add Skill
                    </button>
                </div>
                    
            </Form>
          )}
    </Formik>)
}

export default AddJobClassificationForm;