import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { reset } from '../../state/talentSlice'
import { logout, selectAuth } from '../../state/authSlice'


// Talent Components
import TalentForm from '../archive/TalentForm/TalentForm'
import TalentList from '../admin/AdminTalentList/TalentList'
import TalentProfile from '../talent/TalentProfile/TalentProfile'


// Admin/Production team Components
import AdminHome from '../admin/AdminHome/AdminHome'
import AdminJobPost from '../admin/AdminJobPosts/AdminJobPosts'


// Common Components
import Settings from '../common/SettingsPage/Settings'
import JobPostPage from '../common/JobPost/JobPostPage'
import PrivateRoute from '../common/PrivateRoute'

// Auth Components
import AuthPage from '../common/AuthComponents/AuthPage'
import SignIn from '../common/AuthComponents/SignIn'
import SignUp from '../common/AuthComponents/SignUp'
import SideBanner from '../common/AuthComponents/SideBanner';
import SignInOptions from '../common/AuthComponents/SignInOptions';


/**
 *  Talent App component
 *  @author Nadia Mayangputri
 */ 
const AdminApp = (props) => {
 
  const auth = useSelector(selectAuth)
  const dispatch = useDispatch()


  // useEffect(() => {
  //   if(auth.isAuthenticated) {
  //     // dispatch(fetchTalent(auth.token))
  //   } else {
  //     dispatch(logout())
  //     dispatch(reset())
  //   }
  // }, auth)
  

  return (      
        <Switch>

            <PrivateRoute exact={true} path='/' component={AdminHome} />
            <PrivateRoute exact={true} path='/job-posts' component={AdminJobPost} />
            <PrivateRoute exact={true} path='/job-posts/:id' component={JobPostPage} />
            <PrivateRoute exact={true} path='/job-posts/edit/:id' component={JobPostPage} />
            <PrivateRoute exact={true} path='/talents' component={TalentList} />
            {/* <PrivateRoute exact={true} path='/new-talent' component={TalentForm} /> 
            <PrivateRoute exact={true} path='/talents/:id' component={TalentProfile} /> */}
            <PrivateRoute exact={true} path='/settings' component={Settings} />

            {/* Talent routes */}
            {/* <Route path="/opportunities">
              <Redirect to="/"/>
            </Route>
            <Route path="/profile">
              <Redirect to="/"/>
            </Route> */}

            <Route exact path="/signin">
            {auth.isAuthenticated ? <Redirect to="/"/> :            
                <SideBanner 
                bgColor="linear-gradient(230deg,#ff5554, #a24bcf,#4b79cf) 0% 0% / 300% 300%"
                bgOpacity={1}
                extra={<SignInOptions />}
                note={<a href="https://stories.freepik.com/work">Illustration by Freepik Stories</a>}
                bgImage="audio.jpg"/>
            }
            </Route>


        </Switch>            
  );
  
}

export default AdminApp;
