import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import JobApplicationService from '../services/jobApplication.service';
import JobPostService from '../services/jobPost.service';

const initialState = {
    data: {data: [], ids: []},
    status: 'idle',
    error: null
}


/**
   * Get all Job posts
   */
export const fetchJobApplications = createAsyncThunk('jobApplication/fetchJobApplications', async (token) => {
    const jobApplications = await JobApplicationService.getAll(token)

    let data = []
    let ids = []
    for(let application of jobApplications.data) {
      const jobApplicationData = await JobPostService.get(application.jobpost_id, token)
      
      data.push(jobApplicationData.data)
      ids.push(jobApplicationData.data.id)
    }
    return {data: data, ids: ids}
})



export const jobApplicationSlice = createSlice({
  name: 'jobApplication',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchJobApplications.pending]: (state, action) => {
      state.status = 'loading'
    },
   
    [fetchJobApplications.fulfilled]: (state, action) => {
      state.data.data = action.payload.data
      state.data.ids = action.payload.ids
      state.status = 'succeeded'
    },
   
    [fetchJobApplications.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  }
});

export const selectJobApplications = (state) => state.jobApplication.data

export default jobApplicationSlice.reducer
