import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

import { useSelector } from 'react-redux'
import { selectTalent } from '../../../state/talentSlice'
import { selectTalentJobClassification } from '../../../state/talentJobClassificationSlice'
import { selectTalentPastExperience } from '../../../state/talentPastExperienceSlice'
import { selectTalentLanguages } from '../../../state/talentLanguagesSlice'
import { selectTalentLanguagePair } from '../../../state/talentLanguagePairSlice'

import { selectAuth } from '../../../state/authSlice'
import { selectPublishedJobPosts } from '../../../state/jobPostSlice'
import { selectJobApplications } from '../../../state/jobApplicationSlice'

// Data services
import JobPostService from '../../../services/jobPost.service'


import { Skeleton, Progress, Empty } from 'antd';

import TalentCard from '../../common/Card/TalentCard/TalentCard'
import Header from '../../common/Header/Header';
import JobPostCard from '../../common/JobPost/JobPostCard'
import EmptyCard from '../../common/Card/EmptyCard'

// Icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

// reactstrap components
import {
    Row,
    Col,
    Container
  } from "reactstrap"


import './TalentHome.css'


/**
 * Home page for Talents
 */
const TalentHome = (props) => {
    const [profileCompletion, setProfileCompletion] = useState(100)

    // const [jobPosts, setJobPosts] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    
    const history = useHistory()
    const location = useLocation()
    const auth = useSelector(selectAuth)


    const jobPosts = useSelector(selectPublishedJobPosts)
    const jobPostStatus = useSelector((state) => state.jobPost.status)


    const jobApplications = useSelector(selectJobApplications)
    
    // Selects talent data from Redux store
    const talent = useSelector(selectTalent)
    const talentJobClassification = useSelector(selectTalentJobClassification)
    const talentExperience = useSelector(selectTalentPastExperience)
    const talentLanguages = useSelector(selectTalentLanguages)
    const talentLanguagePairs = useSelector(selectTalentLanguagePair)

    useEffect(() => {

        // Calculate profile completion 
        var percentage = 0;
        
        talent.given_name !== "" && (percentage += 5)
        talent.last_name !== "" && (percentage += 5)
        talent.headline !== "" && (percentage += 5)
        talent.phone_number !== "" && (percentage += 5)
        talent.country !== "" && (percentage += 5)
        talent.city !== "" && (percentage += 5)
        talent.avatar !== "" && (percentage += 10)

        if(Object.keys(talentJobClassification).length > 0 && Object.keys(talentExperience).length > 0) {
            talentJobClassification.jobClassifications.length > 0 && (percentage += 20)
            talentExperience.pastExperience.length > 0 && (percentage += 20)
            talentExperience.highlightedExperience.length > 0 && (percentage += 5)
        }

        setProfileCompletion(percentage)

    }, [talent, talentJobClassification, talentExperience])

    // if(Object.keys(talent).length === 0 && !location.pathname.includes('profile/edit')) {
    //     history.push('/profile/edit')
    //     alert("Your profile data could not be found on our system. Please fill in your data.")
    // }

    // Only show talent profile card id profile completion is 100%,
    // Otherwise, show progress of profile completion instead
    var profile =
    <div>
        <TalentCard 
            key={talent.id} 
            identifier={talent.id} 
            name={talent.nickname} 
            btnDisplay="none"
            text={
                <div className="profile-header-text">
                    <Row>
                        <Col>
                            <h5>{talent.given_name + " " + talent.last_name}</h5>
                            <span>{talent.headline}</span>

                        </Col>
                    </Row>                  
                </div>}
            birthdate={talent.birthdate} 
            img={talent.avatar}
            imgSize="120px"
            imgMargin="30px"
            showProfileLink={false}
        />
    </div>

    if(profileCompletion < 100) {
        profile = 
        <>
        <Row className="profile-completion-progress mb-5">
            <Col>
                <Progress
                    type="circle"
                    strokeColor={{
                        '0%': '#00bcdf',
                        '100%': '#108ee9',

                    }}
                    percent={profileCompletion}
                    width={200}
                    />
                   
                </Col>
            
            <Col className="pt-5">
                <h4>Your profile is not completed</h4>
                <Link to="/profile">
                    <button className="btn pink-btn">Complete Profile</button>
                </Link>
            </Col>
        </Row>
        </>
    }

    // Show loading elements when talent data is not yet fetched
    var header = `Hi, ${talent.given_name}!`
    var subheader = <p>Welcome to your Home page</p>
    var headerBg = <div className="bg-illustration">
        <img src={require("../../../assets/background/bg-light.png")}></img>
    </div>
    if(talent.given_name === undefined) {
        header = <Skeleton.Input style={{ width: 200 }} active={true} />
        subheader = <Skeleton.Input style={{ width: 300 }} active={true} />
        headerBg = <></>
    }

    return(
        <div className="home-container">
            {headerBg}
            <Header 
                title={header}
                subtext={subheader}
            />
            <Container className="content-container">
                <Row>
                    <Col md="6">
                        <div>
                            <h2>Profile</h2>
                        </div>
                        <div className="mt-3">
                            {profile}
                        </div>
                    </Col>

                    <Col md="6">
                    <Row>
                        <div style={{width: "100%"}}>
                            <div className="spaced-between">
                                <h2>Applied Jobs</h2>
                                <Link to=""><span>See all</span></Link>
                            </div>
                            
                            {jobApplications.data.length > 0 ? (jobApplications.data.slice(0,2).reverse().map((job) => {
                                var bgColor = "#EFF1F6", color = "#35424a";

                                if(job.status === "In Review") {
                                    // bgColor = "#ed5e94"
                                    bgColor="linear-gradient(40deg, #108ee9, #70cde7)"
                                    color = "rgba(255, 255, 255, 0.9)"
                                } 

                                return(
                                    <div className="mb-3">
                                    <JobPostCard 
                                        jobId={job.id}
                                        title={job.job_title}
                                        labelTop={job.status}
                                        empType={job.emp_type_id}
                                        company={job.company}
                                        showCompany={job.show_company}
                                        location={job.city + ", " + job.country}
                                        labelBottom={<span className="text-muted">Submitted</span>}
                                        bgColor={bgColor}
                                        textColor={color}
                                        width="100%"
                                        showIcon={false}
                                    />
                                    </div>
                                )
                            })) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You have no recent application" />
                            )}
                        </div>
                    
                    </Row>                       
                    </Col>
                    </Row>
                    <Row className="mt-4">

                        <div style={{width: "100%"}}>
                            <div className="spaced-between">
                                <h2>Opportunities for you</h2>
                                <Link to="/opportunities"><span>See more</span></Link>
                            </div>
                            <div className="suggested">
                            {jobPosts.length > 0 ? (jobPosts.slice(0,3).reverse().map((job) => {
                                var bgColor = "rgba(237, 239, 245, 0.6)", color = "#35424a", iconColor = "#35424a";
                                
                                return(
                                        <div className="mb-3">
                                        <JobPostCard 
                                            jobId={job.id}
                                            title={<Link className="link" to={`/opportunities/${job.id}`}>{job.job_title}</Link>}
                                            company={job.company_id}
                                            showCompany={job.show_company}
                                            empType={job.emp_type_id}
                                            location={job.city + ", "+ job.country}
                                            bgColor={bgColor}
                                            textColor={color}
                                            iconColor={iconColor}                                         
                                            width="100%"
                                            extra={
                                                <>
                                                    <div className="text-right w-100 text-muted text-xs">{moment(job.dt_published, "YYYY-MM-DD").fromNow()}</div>
                                                </>
                                            }
                                        />
                                        </div>
                                    
                                )
                            })) : (
                                 // If it's loading, show loading card component, if it's not it means there is no data to load
                                 jobPostStatus === 'loading' ? (  
                                    <>
                                         <EmptyCard />
                                         <EmptyCard />
                                         <EmptyCard />
                                     </>
                                 ) : (
                                    <>
                                        <div></div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, there is no opportunity for you at this moment"/>
                                        <div></div>
                                    </>
                                 )
                            )}
                            </div>
                        </div>
                    
                </Row>
            </Container>
        </div>
    )
    
}

export default TalentHome
