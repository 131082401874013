import React, { useState, useEffect } from 'react';
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup';
import moment from 'moment';
import csc from 'country-state-city';

import propType from 'prop-types'

import { useSelector } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'
import { 
  selectAllJobClassifications, 
  selectAllProjectTypes, 
  selectAllProjectSubtypes, 
  selectAllEmpTypes
} from '../../../state/coreSlices'

import { selectAllCompanies } from '../../../state/companySlice'


import {
    FormGroup,
    Row,
    Col
  } from "reactstrap";

import { Container } from '@material-ui/core';
import { Breadcrumb, Select, Input, notification } from 'antd'
import { WarningFilled } from '@ant-design/icons'


import FormikDateTime from '../../common/FormikDateTime'

import EmpTypeDataService from "../../../services/empType.service"

import { capitalizeFirstLetter, getNameById } from '../../../constants'

const { Option } = Select;

const DATE_FORMAT = 'YYYY-MM-DD'

const jobFormSchema = Yup.object().shape({
    title: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
    company: Yup.string()
    .required('Required'),
    jobClassification: Yup.string()
    .required('Required'),
    country: Yup.string()
    .required('Required'),
    description: Yup.string()
    .required('Required'),
    requirements: Yup.string()
    .required('Required'),
    projType: Yup.string()
    .required('Required'),
    projSubtype: Yup.string()
    .required('Required'),

});


const JobPostForm = (props) => {
    const { data, submitHandler } = props
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    // const [empTypes, setEmpTypes] = useState([])

    const auth = useSelector(selectAuth)
    const projTypes = useSelector(selectAllProjectTypes)
    const projSubtypes = useSelector(selectAllProjectSubtypes)
    const jobClassifications = useSelector(selectAllJobClassifications)
    const empTypes = useSelector(selectAllEmpTypes)
    const companies = useSelector(selectAllCompanies)

    const location = useLocation()

    const [initialValues, setInitialValues ] = useState({ 
      title:"",
      projType: "",
      projSubtype: "",
      jobClassification: "",
      company:"",
      country:"",
      city: "",
      state: "",
      empType:"",
      closingDate:"",
      description: "",
      requirements: "",
      hideCompany: false,
      status: ""
   })
  
    useEffect(() => {
        // Get list of countries
        setCountries(csc.getAllCountries())

        // Get employment types
        // EmpTypeDataService.getAll(auth.token)
        // .then((res) => {
        //   setEmpTypes(res.data)
        // })

        // if the form is used for editing existing data, set initial values to from the existing data 
        if(location.pathname.includes("edit") && Object.keys(data).length > 0) {
            setInitialValues({ 
                title: data.job_title,
                projType: data.project_id,
                projSubtype: data.project_sub_type,
                jobClassification: data.job_classification_id,
                company: data.company_id,
                country: "",
                city: "",
                state: "",
                empType: data.empy_type,
                closingDate: moment(data.job_closing_dt, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
                description: data.description,
                requirements: data.requirements,
                hideCompany: !data.show_company
            })
        } 
          
    }, [auth, data])

    return(
    <>

      <Formik
      enableReinitialize 
      initialValues={initialValues}
      validationSchema={jobFormSchema}
      onSubmit={(values, { setSubmitting }) => {
              let data = new FormData();
              let city = values.city
              if(values.city === "" && values.state !== "") {
                city = values.state
              }
              
              data.append('job_title', values.title);
              data.append('description', values.description);
              data.append('requirements', values.requirements);
              data.append('city', city);
              data.append('country', values.country);

              data.append('show_company', !values.hideCompany)
              
              data.append('emp_type_id', parseInt(values.empType));
              data.append('project_sub_type', parseInt(values.projSubtype));
              data.append('project_id', parseInt(values.projType));
              data.append('company_id', parseInt(values.company));
              data.append('job_classification_id', parseInt(values.jobClassification));
              data.append('job_closing_dt', moment(values.closingDate,'DD/MM/YYYY').format(DATE_FORMAT));

              var today = new Date();
              var dd = String(today.getDate()).padStart(2, '0');
              var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0
              var yyyy = today.getFullYear();

              today = yyyy + '-' + mm + '-' + dd;

              data.append('dt_published', today);
              data.append('status', parseInt(values.status))

              submitHandler(data)
              .then(() => {
                setSubmitting(false);
                window.location.href = '/job-posts'
              }) 
              .catch((err) => {
                notification.open({
                  message: 'Error',
                  description: err.message,
                  icon: <WarningFilled style={{ color: '#ed5e94' }} />,
                })
              })  
          }}>
            { (props) => (
            <Form>          
                <Row>
                    <Col xs="12" md="6">
                        <FormGroup>
                            <label>Project Type</label>
                            <Field
                                type="text"
                                label="Project Type"
                                name="projType"
                                id="projType">
                            {({ field, form: { isSubmitting } }) => {
                              var optionalProps = {};
                              // Only add the value props when form is used for edit
                              if(location.pathname.includes('edit')) {
                                optionalProps.value = getNameById(projTypes, props.values.projType)
                              }
                              return(
                                <Select
                                {...field} 
                                {...optionalProps}  
                                disabled={isSubmitting}
                                showSearch
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    props.setFieldValue('projType', `${e}`)
                                    props.setFieldValue('projSubtype', "")
                                }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().startsWith(input.toLowerCase())
                                  }
                                >
                                {projTypes.map(projType => (
                                    <Option key={projType.id}>{capitalizeFirstLetter(projType.name)}</Option>
                                ))}
                                </Select>
                                )}}

                            </Field>
                            
                            <ErrorMessage name="projType" component="span" />
                        </FormGroup>
                    </Col>

                    <Col xs="12" md="6">
                        <FormGroup>
                            <label>Project Subtype</label>
                            <Field
                                type="text"
                                label="Project Subtype"
                                name="projSubtype"
                                id="projSubtype">
                            {({ field, form: { isSubmitting } }) => {
                              var optionalProps = {};
                              // Only add the value props when form is used for edit
                              if(location.pathname.includes('edit')) {
                                optionalProps.value = getNameById(projSubtypes, props.values.projSubtype)
                              }
                              return(
                                <Select
                                {...field} 
                                {...optionalProps}
                                disabled={isSubmitting || props.values.projType === ""}
                                showSearch
                                // value={
                                //   props.values.projSubtype !== "" ? (getNameById(projSubtypes, props.values.projSubtype)) : ("")}
                                placeholder="Project Subtype"
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    props.setFieldValue('projSubtype', `${e}`)
                                }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().startsWith(input.toLowerCase())
                                  }
                                >
                                {projSubtypes.map(projSubtype => (
                                    <Option key={projSubtype.id}>{capitalizeFirstLetter(projSubtype.name)}</Option>
                                ))}
                                </Select>
                                )}}

                            </Field>
                          
                            <ErrorMessage name="projSubtype" component="span" />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>

                    <Col xs="12" md="6">
                        <FormGroup>
                            <label>Job Classification</label>
                            <Field
                                type="text"
                                label="Job Classification"
                                name="jobClassification"
                                id="jobClassification">
                            {({ field, form: { isSubmitting } }) => {
                              var optionalProps = {};
                              // Only add the value props when form is used for edit
                              if(location.pathname.includes('edit')) {
                                optionalProps.value = getNameById(jobClassifications, props.values.jobClassification)
                              }
                              return(
                                <Select
                                {...field} 
                                {...optionalProps}
                                disabled={isSubmitting}
                                showSearch
                                placeholder="Job Classification"
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    props.setFieldValue('jobClassification', `${e}`)
                                }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().startsWith(input.toLowerCase())
                                  }
                                >
                                {jobClassifications.map(jobClassification => (
                                    <Option key={jobClassification.id}>
                                      {capitalizeFirstLetter(jobClassification.name.replaceAll("_", " ").toLowerCase())}
                                    </Option>
                                ))}
                                </Select>
                                )}}

                            </Field>
                            
                            <ErrorMessage name="projType" component="span" />
                        </FormGroup>
                    </Col>
                  </Row>
                
            
                <FormGroup>
                    <div>
                        <Row>   
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <label>Job Title</label><br></br>
                                    <Field
                                    type="text"
                                    label="Project Name"
                                    name="title">
                                    {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Job Title" />
                                    )}
                                    </Field>
                                </FormGroup>
                            </Col>
                        </Row>
                            
                        <Row>
                          <Col xs="12" md="6">
                              <FormGroup>
                                  <label>Company</label>
                                  <Field
                                      type="text"
                                      label="Company"
                                      name="company"
                                      id="company">
                                  {({ field, form: { isSubmitting } }) => {
                                    var optionalProps = {};
                                    // Only add the value props when form is used for edit
                                    if(location.pathname.includes('edit')) {
                                      optionalProps.value = getNameById(companies, props.values.company)
                                    }
                                    return(
                                      <Select
                                      {...field} 
                                      {...optionalProps}
                                      disabled={isSubmitting}
                                      showSearch
                                      // value={
                                      //   props.values.company !== "" ? (getNameById(companies, props.values.company)) : ("")}
                                      placeholder="Company"
                                      style={{ width: '100%' }}
                                      onChange={(e) => {
                                          props.setFieldValue('company', `${e}`)
                                      }}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                          option.children.toLowerCase().startsWith(input.toLowerCase())
                                        }
                                      >
                                      {companies.map(company => (
                                          <Option key={company.id}>{capitalizeFirstLetter(company.name)}</Option>
                                      ))}
                                      </Select>
                                      )}}

                                  </Field>
                                
                                  <ErrorMessage name="projSubtype" component="span" />
                              </FormGroup>
                          </Col>
                            
                          
                        </Row>

                        <Row>
                            <Col xs="12" md="6">
                              <FormGroup>
                                <Field
                                  name="hideCompany"
                                  type="checkbox">
                                    {({ field, form: { isSubmitting } }) => (
                                    <div className="custom-control custom-checkbox mb-3">
                                      <input
                                        {...field}
                                        disabled={isSubmitting}
                                        className="custom-control-input"
                                        name="hideCompany"
                                        type="checkbox"
                                        id="hideCompany" />

                                      <label className="custom-control-label checkbox-label font-weight-normal" htmlFor="hideCompany">
                                        Hide company name on the post
                                      </label>
                                    </div>
                                    )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                            

                        <Row>
                          <Col xs="12" md="6">
                              <FormGroup>
                                  <label>Country</label>
                                  <Field
                                      type="text"
                                      label="Country"
                                      name="country"
                                      id="country">
                                      {({ field, form: { isSubmitting } }) => (
                                      <Select
                                      {...field} disabled={isSubmitting}
                                      showSearch
                                      placeholder="Country"
                                      style={{ width: '100%' }}
                                      onChange={(e) => {
                                          setStates(csc.getStatesOfCountry(e))
                                          let country = csc.getCountryById(e)
                                          props.setFieldValue('country', country.name)
                                          props.setFieldValue('state', "")
                                      }}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                          option.children.toLowerCase().startsWith(input.toLowerCase())
                                        }
                                      >
                                      {countries.map(country => (
                                          <Option key={country.id}>{country.name}</Option>
                                      ))}
                                      </Select>
                                      )}

                                  </Field>
                                  <ErrorMessage name="country" component="span" />
                              </FormGroup>
                          </Col>

                          <Col xs="12" md="6">
                              <FormGroup>
                                  <label>State/Province</label>
                                  <Field
                                      type="text"
                                      label="State"
                                      name="state"
                                      id="state">
                                  {({ field, form: { isSubmitting } }) => (
                                      <Select
                                      {...field} disabled={isSubmitting || props.values.country === ""}
                                      showSearch
                                      placeholder="State/Province"
                                      style={{ width: '100%' }}
                                      onChange={(e) => {
                                          setCities(csc.getCitiesOfState(e))
                                          let state = csc.getStateById(e)
                                          props.setFieldValue('state', state.name)
                                          props.setFieldValue('city', "")

                                      }}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                          option.children.toLowerCase().startsWith(input.toLowerCase())
                                        }
                                      >
                                      {states.map(state => (
                                          <Option key={state.id}>{state.name}</Option>
                                      ))}
                                      </Select>
                                      )}

                                  </Field>
                                  
                                  <ErrorMessage name="state" component="span" />
                              </FormGroup>
                          </Col>

                          <Col xs="12" md="6">
                              <FormGroup>
                                  <label>City</label>
                                  <Field
                                      type="text"
                                      label="City"
                                      name="city"
                                      id="city">
                                  {({ field, form: { isSubmitting } }) => (
                                      <Select
                                      {...field} disabled={isSubmitting || props.values.state === ""}
                                      showSearch
                                      placeholder="City"
                                      style={{ width: '100%' }}
                                      onChange={(e) => {
                                          let city = csc.getCityById(e)
                                          props.setFieldValue('city', city.name)
                                      }}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                          option.children.toLowerCase().startsWith(input.toLowerCase())
                                        }
                                      >
                                      {cities.map(city => (
                                          <Option key={city.id}>{city.name}</Option>
                                      ))}
                                      </Select>
                                      )}

                                  </Field>
                                
                                  <ErrorMessage name="city" component="span" />
                              </FormGroup>
                          </Col>
                      </Row> 

                      
                      <FormGroup>
                          <label>Employment Type</label>
                          <Row className="mt-2">
                            {empTypes.map(empType => {
                              var name = capitalizeFirstLetter(empType.name.replaceAll("_"," ").toLowerCase());
                              var id = empType.id
                              return(
                                <Col xs="3">
                                  <Field
                                    label="Employment Type"
                                    name="empType"
                                    value={`${id}`}
                                    type="radio">

                                    {({ field, form: { isSubmitting } }) => (
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                        {...field}
                                        disabled={isSubmitting}
                                        className="custom-control-input"
                                        id={empType.name}
                                        type="radio"
                                        />
                                        <label className="checkbox-label custom-control-label font-weight-normal" htmlFor={empType.name}>
                                        {name}
                                        </label>
                                    </div>
                                    )}

                                    </Field>
                                </Col>  
                              )
                            })}
                            
                          
                          </Row>
                          <ErrorMessage name="gender" component="span" />
                          
                          </FormGroup>


                          <Row>
                            <Col xs="12" md="6">
                              <FormGroup>
                                <label>Job Closing Date</label>

                                <Field
                                  name="closingDate"
                                  type="date"
                                  label="Closing date"
                                  component={FormikDateTime}
                                  />
                              </FormGroup>
                            </Col>
                            
                          </Row>

                          <Row>
                          <Col xs="12">
                            
                            <FormGroup>
                              <label>Job Description</label><br></br>
                              <Field
                                type="text"
                                label="Job Description"
                                name="description">
                                {({ field, form: { isSubmitting } }) => (<Input.TextArea {...field} disabled={isSubmitting} type="textarea" placeholder="Job Description" />
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          </Row>
                          <Row>
                          <Col xs="12">
                            <FormGroup>
                              <label>Requirements</label><br></br>
                              <Field
                                type="text"
                                label="Requirements"
                                name="requirements">
                                {({ field, form: { isSubmitting } }) => (<Input.TextArea {...field} disabled={isSubmitting} type="textarea" placeholder="Requirements" />
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          
                          </Row>
                            
                    </div>
                
                        
                </FormGroup>
                <div className="spaced-between" style={{width:"100%"}}>
                    <button
                        type="submit"
                        onClick={() => props.setFieldValue('status', '2')}
                        className="btn">
                        Save as Draft
                    </button>
                    <button
                        type="submit"
                        onClick={() => props.setFieldValue('status', '1')}
                        className="btn dark-blue-btn">
                        Publish
                    </button>
                </div> 
          
        </Form>
      )}
      </Formik>
      
    </>
  )
}


export default JobPostForm;