import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {capitalizeFirstLetter} from '../constants'
// Data services
import TalentJobClassificationService from "../services/talentJobClassification.service";


const initialState = {
  data: {
    jobClassifications: [],
    specialisations: []
  },
  status: 'idle',
  error: null
}



/**
 * Fetch Talent Job Classification detail data and structure it to be easily read by components
 */
export const fetchTalentJobClassification = createAsyncThunk('talent/fetchTalentJobClassification', async (token) => {
  const response = await TalentJobClassificationService.getAll(token)
  
  
  var jobClassifications = []
  var specialisations = {}

  for(let i = 0; i < response.data.length; i++) {
    var currData = response.data[i];

    // Get job classification name
    var jobClassificationName = capitalizeFirstLetter(currData.skill_category_id.toLowerCase())

    // Add job classification to array if it isn't there yet 
    if(!jobClassifications.includes(jobClassificationName)) {
        jobClassifications = [...jobClassifications, jobClassificationName]
        specialisations[jobClassificationName] = []
    }
    // Add specialisation data
    if(currData.specialization_id != null) {
        let specialisationName = capitalizeFirstLetter(currData.specialization_id)
        specialisations[jobClassificationName] = [...specialisations[jobClassificationName], specialisationName]

    }                            
        
  }
  var skillData = {
    jobClassifications: jobClassifications,
    specialisations: specialisations
  };

  return skillData
})


// Talent Job Classification Slice
export const talentJobClassificationSlice = createSlice({
  name: 'talentJobClassification',
  initialState,
  reducers: {
    resetTalentJobClassification: (state) => {
      state.data = initialState.data
    }
  },
  extraReducers: {
    [fetchTalentJobClassification.pending]: 
      (state, action) => {
      state.status = 'loading'
    },
    [fetchTalentJobClassification.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },

    [fetchTalentJobClassification.fulfilled]: (state, action) => {
      state.data.jobClassifications = action.payload.jobClassifications;
      state.data.specialisations = action.payload.specialisations
      state.status = 'succeeded'
    }
   
  }
});

export default talentJobClassificationSlice.reducer

export const { resetTalentJobClassification } = talentJobClassificationSlice.actions;

export const selectTalentJobClassification = (state) => state.talentJobClassification.data

  