import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import talentReducer from './talentSlice';
import talentJobClassificationReducer from './talentJobClassificationSlice';
import talentLanguagesReducer from './talentLanguagesSlice'
import talentLanguagePairReducer from './talentLanguagePairSlice'
import talentPastExperienceReducer from './talentPastExperienceSlice'
import talentSavedJobsReducer from './talentSavedJobsSlice'

import { 
  jobClassificationSlice, 
  projectTypesSlice, 
  projectSubtypesSlice, 
  empTypesSlice,
  languagesSlice,
  languageProficienciesSlice,
  genderSlice
} from './coreSlices';

import jobApplicationReducer from './jobApplicationSlice'
import jobPostReducer from './jobPostSlice';
import companyReducer from './companySlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    talent: talentReducer,
    talentJobClassification: talentJobClassificationReducer,
    talentLanguages: talentLanguagesReducer,
    talentLanguagePair: talentLanguagePairReducer,
    talentPastExperience: talentPastExperienceReducer,
    talentSavedJobs: talentSavedJobsReducer,
    jobPost: jobPostReducer,
    company: companyReducer,
    jobApplication: jobApplicationReducer,

    jobClassification: jobClassificationSlice.reducer,
    languages: languagesSlice.reducer,
    languageProficiencies: languageProficienciesSlice.reducer,
    projectTypes: projectTypesSlice.reducer,
    projectSubtypes: projectSubtypesSlice.reducer,
    empTypes: empTypesSlice.reducer,
    gender: genderSlice.reducer,

  },
});
