import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import { selectAllJobClassifications } from '../../../state/coreSlices' 
import { selectTalent, fetchTalentLanguagePair, fetchTalentLanguage } from '../../../state/talentSlice'
import { selectTalentLanguages } from '../../../state/talentLanguagesSlice'
import { selectTalentLanguagePair } from '../../../state/talentLanguagePairSlice'

import { selectAuth } from '../../../state/authSlice'

import AddJobClassificationForm from '../Forms/JobClassificationForm'
import JobClassificationList from '../../common/JobClassificationList'
import StatsCard from '../../common/Card/StatsCard'

// reactstrap components
import {
    Row,
    Col,
    Container,
    FormGroup
} from "reactstrap"


import { Modal, Empty, Select, Divider, Skeleton, Popconfirm, notification } from 'antd'


import AddIcon from '@material-ui/icons/Add';
import { jobIcons } from '../../../constants'
import './TalentProfile.css'



const JobClassifications = (props) => {

    const [modalVisible, setModalVisible] = useState(false)

    const dispatch = useDispatch()
    const auth = useSelector(selectAuth)
    const jobClassifications = useSelector(selectAllJobClassifications)
    const talent = useSelector(selectTalent)
    const talentLanguages = useSelector(selectTalentLanguages)
    const talentLanguagePairs = useSelector(selectTalentLanguagePair)

    return(
    <Container>

         {/* Modal to add new Job Classifications */}
         <Modal
          title={<h4>Add Skills</h4>}
          centered
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={[<></>]}>
          <AddJobClassificationForm handleClose={setModalVisible}/>
        </Modal>
        

        <div className="spaced-between">
            <h2>Skills</h2>
            {props.showAdd && (
            <div>
                <button className="btn m-0 btn-circle purple-btn" onClick={() => setModalVisible(true)}><AddIcon /> </button>
            </div>    
            )}
            
        </div>

        {Object.keys(props.data).length > 0 ? (
        <div className="grid-container mt-3">
                
                {/* If data for job classification exists, */}
                {Object.keys(props.data).map((data, index) => {
                var jobName = data.replaceAll("_", " ")
                var icon = jobIcons[jobName.toUpperCase()]
                var detail = "Specialization"
                var specialisation = props.data[data]
                var extraDetail = <></>;

                // Remove detail header if specialisation does not exist
                if(specialisation.length === 0) {
                    detail = ""
                }
                
                if(jobIcons[jobName.toUpperCase()] === undefined) {
                    icon = jobIcons['OTHER']
                }

                if(jobName === 'Translator') {
                    let languagePairData = [];
                    detail = "Language Pair"
                    talentLanguagePairs.forEach(pair => {
                        languagePairData = languagePairData.concat(`${pair.source} to ${pair.target} (${pair.proficiency})`)
                    })
                    specialisation = languagePairData
                }
                if(jobName === 'Dancer') {
                    detail = "Known Styles"
                }
                if(jobName === 'Singer') {
                    detail = "Vocal Range"
                }

                // Add Languages as extra detail for Voice Actor
                if(jobName === 'Voice actor') {
                    let languageData = []
                    talentLanguages.forEach(language => {
                        languageData = languageData.concat(`${language.language} (${language.proficiency})`)
                    })
                    extraDetail = <>
                    <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="mr-2">
                        {/* <LanguageIcon /> */}
                        <span className="h5 text-uppercase text-muted mb-0">   Language</span>
                        </span>
                    </p>
                    <Row className="pl-2 mt-2">
                        <JobClassificationList data={languageData} bgColor="#e5e8f0" color="#282f48" />
                        {/* <Popconfirm 
                        placement="right" 
                        title="Are you sure you want to delete this skill?" 
                        onConfirm={() => deleteJobClassification()} 
                        okText="Yes" 
                        cancelText="No">
                            <DeleteOutlined
                            style={{color: "#ed5e94"}}/>  
                        </Popconfirm>      */}
                    </Row>
                    </>
                }

                return(
                    <div key={index}>
                        <StatsCard 
                            title={jobName}
                            details={<JobClassificationList data={specialisation} bgColor="rgba(0, 188, 223, 0.9)" color="#fff" />}
                            // icon={icon}
                            detailName={detail}
                            extra={extraDetail}
                        />     
                    </div>  
                )}
            )}
        </div>
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You haven't added any skills yet."/>
    )}
    </Container>
)}

export default JobClassifications;

JobClassifications.propTypes = {
    data: propTypes.array,
    languages: propTypes.array,
    languagePairs: propTypes.array
}

JobClassifications.defaultProps = {
    data: [],
    languages: [],
    languagePairs: []
}