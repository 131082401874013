import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import GenderDataService from '../services/gender.service'
import ProjectTypeDataService from '../services/projectType.service'
import ProjectSubtypeDataService from '../services/projectSubtype.service'
import EmpTypeDataService from '../services/empType.service'
import JobClassificationDataService from "../services/jobClassification.service";
import LanguageDataService from "../services/language.service";
import LanguageProficiencyDataService from "../services/languageProficiency.service";

const initialState = {
    data: [],
    status: 'idle',
    error: null
}

/**
   * Get all job classification/skills from job classification data service
   */
  export const fetchAllGenders = createAsyncThunk('gender/fetchAllGenders', async (token) => {
    const response = await GenderDataService.getAll(token)
    return response.data
})

/**
   * Get all job classification/skills from job classification data service
   */
  export const fetchAllJobClassifications = createAsyncThunk('jobClassification/fetchAllJobClassifications', async (token) => {
    const response = await JobClassificationDataService.getAll(token)
    return response.data
})


/**
   * Get all Project types
   */
  export const fetchAllProjectTypes = createAsyncThunk('projType/fetchAllProjectTypes', async (token) => {
    const response = await ProjectTypeDataService.getAll(token)
    return response.data
})

/**
   * Get all Project Subtypes
   */
  export const fetchAllProjectSubtypes = createAsyncThunk('projType/fetchAllProjectSubtypes', async (token) => {
    const response = await ProjectSubtypeDataService.getAll(token)
    return response.data
})

/**
   * Get all Employment types
   */
  export const fetchAllEmpTypes = createAsyncThunk('empType/fetchAllEmpTypes', async (token) => {
    const response = await EmpTypeDataService.getAll(token)
    return response.data
})

/**
   * Get all languages specialisations from languages data service
   */
  export const fetchAllLanguages = createAsyncThunk('languages/fetchAllLanguages', async (token) => {
    const response = await LanguageDataService.getAll(token)
    return response.data
})

/**
   * Get all language proficiencies specialisations from languages proficiency data service
   */
export const fetchAllLanguageProficiencies = createAsyncThunk('languageProficiencies/fetchAllLanguageProficiencies', async (token) => {
    const response = await LanguageProficiencyDataService.getAll(token)
    return response.data
})


export const genderSlice = createSlice({
  name: 'gender',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllGenders.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllGenders.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = "success"
    },
    [fetchAllGenders.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }

  }
});


export const jobClassificationSlice = createSlice({
  name: 'jobClassification',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllJobClassifications.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllJobClassifications.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = "success"
    },
    [fetchAllJobClassifications.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }

  }
});

export const projectTypesSlice = createSlice({
  name: 'projectTypes',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllProjectTypes.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllProjectTypes.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = "success"
    },
    [fetchAllProjectTypes.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }

  }
});

export const projectSubtypesSlice = createSlice({
  name: 'projectSubtypes',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllProjectSubtypes.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllProjectSubtypes.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = "success"
    },
    [fetchAllProjectSubtypes.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }

  }
});


export const empTypesSlice = createSlice({
  name: 'empTypes',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllEmpTypes.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllEmpTypes.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = "success"
    },
    [fetchAllEmpTypes.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }

  }
});


export const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllLanguages.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllLanguages.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = "success"
    },
    [fetchAllLanguages.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }

  }
});


export const languageProficienciesSlice = createSlice({
  name: 'languageProficiencies',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllLanguageProficiencies.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllLanguageProficiencies.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = "success"
    },
    [fetchAllLanguageProficiencies.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }

  }
});


export const selectGenders = (state) => state.gender.data
export const selectAllJobClassifications = (state) => state.jobClassification.data
export const selectAllProjectTypes = (state) => state.projectTypes.data
export const selectAllProjectSubtypes = (state) => state.projectSubtypes.data
export const selectAllLanguages = (state) => state.languages.data
export const selectAllLanguageProficiencies = (state) => state.languageProficiencies.data
export const selectAllEmpTypes = (state) => state.empTypes.data


