import React from 'react'

// Icons
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { HomeOutlined, TeamOutlined, SolutionOutlined, UserAddOutlined, UserOutlined, SettingOutlined, SendOutlined, AppstoreOutlined } from '@ant-design/icons';


import { LaptopOutlined } from '@ant-design/icons';


import MusicNoteIcon from '@material-ui/icons/MusicNote';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import MovieIcon from '@material-ui/icons/Movie';
import TranslateIcon from '@material-ui/icons/Translate';
import DescriptionIcon from '@material-ui/icons/Description';
import MicIcon from '@material-ui/icons/Mic';
import StarIcon from '@material-ui/icons/Star';
import MicNoneIcon from '@material-ui/icons/MicNone';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import FlareIcon from '@material-ui/icons/Flare';
import WorkIcon from '@material-ui/icons/Work';
import LanguageIcon from '@material-ui/icons/Language';

// Main navigation items details on sidebar for talents
export const talentNavItems = [
  {
    name: "Home",
    path: "/",
    icon: <HomeOutlined />
  },
  {
    name: "Profile",
    path: "/profile",
    icon: <UserOutlined />
  },
  // {
  //   name: "My Projects",
  //   path: "/my-projects",
  //   icon: <SolutionOutlined />
  // },
  {
    name: "Opportunities",
    path: "/opportunities",
    icon: <WorkOutlineIcon style={{fontSize: "16px"}} />
  }
]

// Main navigation items details on sidebar for super admin
export const adminNavItems = [
  {
    name: "Dashboard",
    path: "/",
    icon: <AppstoreOutlined />
  },
  {
    name: "Manage Jobs",
    path: "/job-posts",
    icon: <WorkOutlineIcon style={{fontSize: "16px"}} />
  },
  {
    name: "Projects",
    path: "/projects",
    icon: <SolutionOutlined />
  },
  // {
  //   name: "Find Talent",
  //   path: "/talents",
  //   icon: <TeamOutlined />
  // }
]

// Sidebar subitems
export const navSubItems = [
  {
    name: "Settings",
    path: "/settings",
    icon: <SettingOutlined />
  },
  // {
  //   name: "Contact Support",
  //   path: "/support",
  //   icon: <SendOutlined />
  // }
  // {
  //   name: "New Talent",
  //   path: "/new-talent",
  //   icon: <UserAddOutlined />
  // },
  // {
  //   name: "Browse Talents",
  //   path: "/talents",
  //   icon: <TeamOutlined />
  // }
]



export const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) => {
  return [ first.toLocaleUpperCase(locale), ...rest ].join('');
}

// Find name of a data by id
export const getNameById = (data, id) => {
  let name = "";
  for(let i = 0; i < data.length; i++) {
    console.log(data[i].id)

    if(data[i].id == id) {
      name = capitalizeFirstLetter(data[i].name.replace("_", " ").toLowerCase())
      break
    }
  }    
  return name;
}

export const RoundIcon = (props) => (
  <div className="icon icon-shape text-white rounded-circle shadow" style={{backgroundColor: props.bgColor}}>
      {props.icon}
  </div>
)

export const jobIcons = {
  "DIRECTOR": <RoundIcon bgColor= "#282f48" icon = {<MovieIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "TRANSLATOR": <RoundIcon bgColor= "#ff5554" icon = {<TranslateIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "SCRIPTWRITER": <RoundIcon bgColor= "#11cdef" icon = {<DescriptionIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "VOICE ACTOR": <RoundIcon bgColor= "#4d6fff" icon = {<MicIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "SINGER": <RoundIcon bgColor= "#ED5F95" icon = {<MicNoneIcon style={{color: "rgba(255, 255, 255, 0.4)", fontSize: "0.3em"}}/>} />,
  "ANIMATOR": <RoundIcon bgColor= "#000080" icon = {<LaptopOutlined style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "DANCER": <RoundIcon bgColor= "#fcc203" icon = {<FlareIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "ACTOR": <RoundIcon bgColor= "#5d2491" icon = {<StarIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "INSTRUMENTALIST": <RoundIcon bgColor= "#24dde3" icon = {<MusicNoteIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "MULTI-INSTRUMENTALIST": <RoundIcon bgColor= "#ff6bb0" icon = {<MusicNoteIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />, 
  "COMPOSER": <RoundIcon bgColor= "#ff5274" icon = {<QueueMusicIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "ARANGER": <RoundIcon bgColor= "#40dea9" icon = {<QueueMusicIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
  "OTHER": <RoundIcon bgColor= "#e5e8f0" icon = {<WorkIcon style={{color: "rgba(40, 47, 72, 0.4)"}}/>} />,         
}

export const dummyOpportunities = [
  {
    id: 1,
    title: "Audition - Frozen 2 Voice Actor",
    company: "Central Studios",
    location: "Manila, Philippines",
    empType: "Contract",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    publishedDate: "17 September, 2020",
    closingDate: "17 November, 2020"
  },
  {
    id: 2,
    title: "Dubbing director for Indonesian Projects",
    company: "Tel Productions",
    location: "Jakarta, Indonesia",
    empType: "Contract",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    publishedDate: "20 September, 2020",
    closingDate: "31 October, 2020"
  },
  {
    id: 3,
    title: "Translation work (English to Spanish)",
    company: "Shine Works",
    location: "Anywhere",
    empType: "Contract - Remote",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    publishedDate: "1 October, 2020",
    closingDate: "5 November, 2020"
  }
]

export const savedJobs = [
  {
    id: 3,
    title: "Translation work (English to Spanish)",
    company: "Shine Works",
    location: "Anywhere",
    empType: "Contract - Remote",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    publishedDate: "1 October, 2020",
    closingDate: "5 November, 2020"
  },
  {
    id: 2,
    title: "Dubbing director for Indonesian Projects",
    company: "Tel Productions",
    location: "Jakarta, Indonesia",
    empType: "Contract",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    publishedDate: "20 September, 2020",
    closingDate: "31 October, 2020"
  }
]

export const appliedJobs = [
  {
    id: 1,
    jobId: 4,
    jobTitle: "Musical Backup Dancer",
    company: "Bumble bee",
    location: "Melbourne, Australia",
    status: "In Review"
  },
  {
    id: 2,
    jobId: 5,
    jobTitle: "French to English Translator",
    company: "Extra Group",
    location: "Anywhere",
    status: "Submitted"
  }
]

export const dummyTalents = [
  {
    "id": 3,
    "user": 7,
    "given_name": "Nina",
    "last_name": "Nori",
    "stage_name": "Ninori",
    "is_stage_name_preferred_name": true,
    "headline": "Actress, Singer and Voice Actor",
    "gender": "FEMALE",
    "phone_number": "+61408399287",
    "country": "Australia",
    "city": "Ballarat",
    "avatar": "http://3.25.192.180/static/media/profile/23984f69-8503-43c1-a838-b99154c6df8f.jpg",
    "date_of_birth": "1995-07-05",
    "sample_work_url": "n/a"
},
{
  "id": 7,
  "user": 11,
  "given_name": "Jayden",
  "last_name": "McAfee",
  "stage_name": "Jay",
  "is_stage_name_preferred_name": true,
  "headline": "Animator and Illustrator",
  "gender": "MALE",
  "phone_number": "+61402988784",
  "country": "Australia",
  "city": "Australia",
  "avatar": "http://3.25.192.180/static/media/profile/7bc4956f-1025-4e29-bd17-20afecd18750.png",
  "date_of_birth": "1993-05-03",
  "sample_work_url": "n/a"
}

]