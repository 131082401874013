import React, { useEffect, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'
import csc from 'country-state-city';

import { capitalizeFirstLetter } from '../../../constants'

import { Select } from 'antd';

import {
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Button,
    Modal
  } from "reactstrap";


import FormikDateTime from '../../common/FormikDateTime'

import GenderDataService from "../../../services/gender.service"

const { Option } = Select;

/**
 * Third page of sign up form
 * @author Nadia Mayangputri
 */
const SignUpStep3 = (props) => {
    const { formikProps } = props
    const location = useLocation()
    const auth = useSelector(selectAuth)

    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [genders, setGenders] = useState([])

    useEffect(() => {
        setCountries(csc.getAllCountries())
        GenderDataService.getAll(auth.token)
        .then((res) => {
            setGenders(res.data.reverse())
        })
    }, [setGenders])
    
    return (
        <>
            <Row>
                <Col xs="12">
                    <FormGroup>
                        <label>Country</label>
                        <Field
                            type="text"
                            label="Country"
                            name="country"
                            id="country">
                            {({ field, form: { isSubmitting } }) => (
                            <Select
                            {...field} disabled={isSubmitting}
                            showSearch
                            placeholder="Country"
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                setStates(csc.getStatesOfCountry(e))
                                let country = csc.getCountryById(e)
                                formikProps.setFieldValue('country', country.name)
                                formikProps.setFieldValue('state', "")
                                formikProps.setFieldValue('mobile', `+${country.phonecode}`)
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().startsWith(input.toLowerCase())
                              }
                            >
                            {countries.map(country => (
                                <Option key={country.id}>{country.name}</Option>
                            ))}
                            </Select>
                            )}

                        </Field>
                        <ErrorMessage name="country" component="span" />
                    </FormGroup>
                </Col>

                <Col xs="12" md="6">
                    <FormGroup>
                        <label>State/Province</label>
                        <Field
                            type="text"
                            label="State"
                            name="state"
                            id="state">
                        {({ field, form: { isSubmitting } }) => (
                            <Select
                            {...field} disabled={isSubmitting || formikProps.values.country === ""}
                            showSearch
                            placeholder="State/Province"
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                setCities(csc.getCitiesOfState(e))
                                let state = csc.getStateById(e)
                                formikProps.setFieldValue('state', state.name)
                                formikProps.setFieldValue('city', "")

                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().startsWith(input.toLowerCase())
                              }
                            >
                            {states.map(state => (
                                <Option key={state.id}>{state.name}</Option>
                            ))}
                            </Select>
                            )}

                        </Field>
                        
                        <ErrorMessage name="state" component="span" />
                    </FormGroup>
                </Col>

                <Col xs="12" md="6">
                    <FormGroup>
                        <label>City</label>
                        <Field
                            type="text"
                            label="City"
                            name="city"
                            id="city">
                        {({ field, form: { isSubmitting } }) => (
                            <Select
                            {...field} disabled={isSubmitting || formikProps.values.state === ""}
                            showSearch
                            placeholder="City"
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                let city = csc.getCityById(e)
                                formikProps.setFieldValue('city', city.name)
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().startsWith(input.toLowerCase())
                              }
                            >
                            {cities.map(city => (
                                <Option key={city.id}>{city.name}</Option>
                            ))}
                            </Select>
                            )}

                        </Field>
                       
                        <ErrorMessage name="city" component="span" />
                    </FormGroup>
                </Col>
            </Row>    

            <FormGroup>
            <label>Mobile Number</label>
            <Field
                type="text"
                label="Mobile number"
                name="mobile"
                id="mobile">
                {({ field, form: { isSubmitting } }) => (
                    <InputGroup >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-mobile-button" style={{color: "#b5b5b5"}}/>
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input {...field} disabled={isSubmitting} type="text" placeholder="Mobile number" />
                    </InputGroup>
                )}
            </Field>
            <ErrorMessage name="mobile" component="span" />

        </FormGroup>

              
        <FormGroup>
            <label>Gender</label>
            {// On edit, show dropdown selection of gender, and auto select what's recorded on the db
            location.pathname.includes('settings') ? (
                <Row>
                    <Col xs="12" md="6">
                        <FormGroup>
                            <Field
                                type="text"
                                label="Gender"
                                name="gender"
                                id="gender-select">
                            {({ field, form: { isSubmitting } }) => {
                                 var optionalProps = {};
                                 // Only add the value props once talent data is retrieved
                                 if(formikProps.values.gender_type !== "") {
                                   optionalProps.value = capitalizeFirstLetter(formikProps.values.gender_type.toLowerCase().replaceAll("_", " "))
                                 }

                                return(
                                <Select
                                {...field} 
                                {...optionalProps}
                                disabled={isSubmitting}
                                showSearch
                                placeholder="Gender"
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    formikProps.setFieldValue('gender', e)
                                }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().startsWith(input.toLowerCase())
                                }
                                >
                                {genders.map(gender => (
                                    <Option key={gender.id}>{capitalizeFirstLetter(gender.gender_type.toLowerCase().replaceAll("_", " "))}</Option>
                                ))}
                                </Select>)
                                }}

                            </Field>
                        
                            <ErrorMessage name="gender" component="span" />
                        </FormGroup>
                    </Col>
                </Row>
            ) : (
            // If its on profile creation, display gender options as radio buttons 
            <Row className="mt-2">
                {/* {genders.map(gender => {
                    return(
                        <Col xs="3">
                            <Field
                                label="Gender"
                                name="gender"
                                value={gender.id}
                                type="radio">

                                {({ field, form: { isSubmitting } }) => (
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                    {...field}
                                    disabled={isSubmitting}
                                    className="custom-control-input"
                                    id={gender.id+gender.gender_type}
                                    type="radio"
                                    />
                                    <label className="checkbox-label custom-control-label font-weight-normal" htmlFor={gender.id+gender.gender_type}>
                                    {capitalizeFirstLetter(gender.gender_type.toLowerCase().replaceAll("_", " "))}
                                    </label>
                                </div>
                                )}

                            </Field>
                        </Col>
                    )
                })} */}

                <Col xs="3">
                <Field
                    label="Gender"
                    name="gender"
                    value="2"
                    type="radio">

                    {({ field, form: { isSubmitting } }) => (
                    <div className="custom-control custom-radio mb-3">
                        <input
                        {...field}
                        disabled={isSubmitting}
                        className="custom-control-input"
                        id="female"
                        type="radio"
                        />
                        <label className="checkbox-label custom-control-label font-weight-normal" htmlFor="female">
                        Female
                        </label>
                    </div>
                    )}

                </Field>
                </Col>
                <Col xs="3">
                <Field
                    label="Gender"
                    name="gender"
                    value="1"
                    type="radio">
                    {({ field, form: { isSubmitting } }) => (
                    <div className="custom-control custom-radio mb-3">
                        <input
                        {...field}
                        disabled={isSubmitting}
                        className="custom-control-input"
                        id="male"
                        type="radio"
                        />
                        <label className="checkbox-label custom-control-label font-weight-normal" htmlFor="male">
                        Male
                        </label>
                    </div>
                    )}
                </Field>
                </Col>
                
                <Col xs="3">
                <Field
                    label="Gender"
                    name="gender"
                    value="3"
                    type="radio">
                    {({ field, form: { isSubmitting } }) => (
                    <div className="custom-control custom-radio mb-3">
                        <input
                        {...field}
                        disabled={isSubmitting}
                        className="custom-control-input"
                        id="other"
                        type="radio"
                        />
                        <label className="checkbox-label custom-control-label font-weight-normal" htmlFor="other">
                        Other
                        </label>
                    </div>
                    )}
                </Field>
                </Col>
            
            </Row>
            )}
            <ErrorMessage name="gender" component="span" />
            
            </FormGroup>

            <FormGroup>
            <label>Date of birth</label>

            <Field
                name="birthdate"
                type="date"
                label="Birthdate"
                component={FormikDateTime}
                />
            </FormGroup>

    </>
  );
};

export default SignUpStep3;
