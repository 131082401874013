import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Field, Form } from "formik";

import { useSelector } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'

import AuthDataService from '../../../services/auth.service'

// UI Components Libary
import {
    FormGroup,
    Row,
    Col, 
    Container 
  } from "reactstrap";

import { Skeleton, Tabs, Divider , message, notification, Card, Input} from 'antd';

import { WarningFilled } from '@ant-design/icons'

import Header from '../Header/Header';
import './Settings.css'
import TalentBasicInfoForm from '../../talent/Forms/BasicInfoForm'

const { TabPane } = Tabs;

const ChangePasswordForm = () => {

    const [accountEmail, setAccountEmail] = useState("")
    const auth = useSelector(selectAuth)

    useEffect(() => {
        AuthDataService.getAuthDetail(auth.token)
        .then(res => {
            setAccountEmail(res.data.email)
        })
    })
    return(
        <>
        <p><b>Change Password</b></p>
        <Formik
        enableReinitialize
        initialValues={{newPassword: null}}
        
        onSubmit={(values,{ setSubmitting, resetForm }) => {
            let data = new FormData()
            data.append('password', values.newPassword)

            // Create Job Application data
            AuthDataService.changePassword(data, auth.token, {
                headers: {
                // Overwrite Axios's automatically set Content-Type
                'content-type': 'multipart/form-data'
                }
            })
            .then(() => {
                setSubmitting(false);
                message.success("Password changed!")
                resetForm()
            }) 
            .catch((err) => {
                notification.open({
                message: 'Error',
                description: err.message,
                icon: <WarningFilled style={{ color: '#ed5e94' }} />,
                })
                setSubmitting(false);
            })  
            
            }}>
            { (props, isSubmitting) => (
            <Form>
                    <label>Email</label>
                    <br></br>
                    <span className="text-muted">{accountEmail}</span>


                    <FormGroup className="mt-2">
                        <label>New Password</label><br></br>
                        <Field
                        type="password"
                        label="New Password"
                        name="newPassword">
                        {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="password" placeholder="Enter new Password" />
                        )}
                        </Field>
                    </FormGroup>
                       
                    
                    <button
                        type="submit"
                        className="px-4 py-1 m-auto d-block rounded purple-btn">
                        Save
                    </button>
            </Form>
        )}
      
      </Formik>
      </>
    )
}

const Settings = () => {
    const auth = useSelector(selectAuth)
    
    return(
        <div className="settings-container tab-light-bg">
            {/* <div className="bg-illustration">
                <img src={require("../../../assets/background/bg-light.png")}></img>
            </div> */}
            <Header 
                title="Settings"
            />

        
            <div className="tab-container">
                <Tabs defaultActiveKey="1" className="tab-content" centered tabBarGutter={70}>
                    <TabPane tab="MANAGE PROFILE" key="1">
                        {auth.userType === 'TALENT' ? (<TalentBasicInfoForm />) : (<></>)}                   
                    </TabPane>

                    <TabPane tab="ACCOUNT" key="2"> 
                        <Container>  
                            {/* <Card style={{ width: '100%', marginBottom: '20px' }}>
                                <Card.Meta
                                // avatar={<div className="avatar-preview" style={{backgroundImage: `url(${previewAvatar})`}}></div>}
                                description={<ChangePasswordForm  />}
                                />
                            </Card> */}
                        </Container>
                   </TabPane>
                    
                </Tabs>
            </div>
        </div>
    )
    
}

export default Settings;