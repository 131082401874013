import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { selectTalent, fetchTalent } from '../../state/talentSlice'
import { fetchTalentJobClassification } from '../../state/talentJobClassificationSlice'
import { fetchTalentPastExperience } from '../../state/talentPastExperienceSlice'
import { fetchTalentLanguage } from '../../state/talentLanguagesSlice'
import { fetchTalentLanguagePair } from '../../state/talentLanguagePairSlice'
import { fetchTalentSavedJobs } from '../../state/talentSavedJobsSlice'
import { fetchJobApplications } from '../../state/jobApplicationSlice'

import { logout, selectAuth } from '../../state/authSlice'

// Talent Components
import TalentHome from '../talent/TalentHome/TalentHome'
import TalentProfile from '../talent/TalentProfile/TalentProfile'
import ProjectForm from '../talent/Forms/ProjectForm'
import BasicDataForm from '../talent/Forms/BasicInfoForm'
import TalentJobPostList from '../talent/TalentJobPostList/TalentJobPostList'


// Common Components
import Settings from '../common/SettingsPage/Settings'
import JobPostPage from '../common/JobPost/JobPostPage'
import PrivateRoute from '../common/PrivateRoute'

import { notification } from 'antd'
import { WarningFilled } from '@ant-design/icons'

// Auth Components
import SideBanner from '../common/AuthComponents/SideBanner';
import SignInOptions from '../common/AuthComponents/SignInOptions';


/**
 *  Talent App component
 *  @author Nadia Mayangputri
 */ 
const TalentApp = (props) => { 
  const auth = useSelector(selectAuth)
  const talent = useSelector(selectTalent)
  const talentStatus = useSelector(state => state.talent.status)

  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    // Fetch talent data if talent is authenticated
    if(auth.isAuthenticated) {
      dispatch(fetchTalent(auth.token))
      dispatch(fetchTalentPastExperience(auth.token))
      dispatch(fetchTalentJobClassification(auth.token))
      dispatch(fetchTalentLanguage(auth.token))
      dispatch(fetchTalentLanguagePair(auth.token))
      dispatch(fetchTalentSavedJobs(auth.token))
      dispatch(fetchJobApplications(auth.token))
    }


  }, [auth])

  useEffect(() => {
    // Redirect to settings if basic info is not yet filled
    if(talent.given_name === "" || talent.headline === "" || talentStatus === "failed") {
        notification.open({
          message: 'Data not found',
          description: "Your data was not recorded in our system, please fill in your details to proceed.",
          icon: <WarningFilled style={{ color: '#ed5e94' }} />,
          duration: 6
        })
        // alert("Your data was not recorded in your system, please fill in your details to proceed.")
        if(location.pathname !== '/settings') {
          window.location.href = '/settings'
        }
    }

  }, [location, talentStatus])
  

  return (      
        <Switch>
            <PrivateRoute exact={true} path='/' component={TalentHome} />
            <PrivateRoute exact={true} path='/opportunities' component={TalentJobPostList} />
            <PrivateRoute exact={true} path='/opportunities/:id' component={JobPostPage} />
            <PrivateRoute exact={true} path='/profile' component={TalentProfile} />
            {/* <PrivateRoute exact={true} path='/profile/add-project' component={ProjectForm} /> */}
            <PrivateRoute exact={true} path='/profile/edit' component={BasicDataForm} />
            <PrivateRoute exact={true} path='/settings' component={Settings} />


            {/* <PrivateRoute exact={true} path='/talents' component={TalentList} />
            <PrivateRoute exact={true} path='/new-talent' component={TalentForm} /> 
            <PrivateRoute exact={true} path='/talents/:id' component={TalentProfile} /> */}

            <Route exact path="/signin">
            {auth.isAuthenticated ? <Redirect to="/"/> :            
                <SideBanner 
                bgColor="linear-gradient(230deg,#ff5554, #a24bcf,#4b79cf) 0% 0% / 300% 300%"
                bgOpacity={1}
                extra={<SignInOptions />}
                note={<a href="https://stories.freepik.com/work">Illustration by Freepik Stories</a>}
                bgImage="audio.jpg"/>
            }
            </Route>
        </Switch>            
  );
  
}

export default TalentApp;
