import axios from "axios";

export default axios.create({
  // Dev Server
  // baseURL: "http://3.25.192.180/",
  // Staging Server
  baseURL: "http://54.153.173.252/",
  // Local Server
  // baseURL: "http://localhost:8000/",
  headers: {
    "Content-type": "application/json"
  }
});