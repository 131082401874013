import React, { useEffect, useState } from "react";
import { Field, ErrorMessage } from "formik";
// import Input from "@material-ui/core/Input";

import { useSelector } from 'react-redux'

import {
    Input,
    FormGroup,
    Row,
    Col
  } from "reactstrap";

import { Card } from 'antd'
/**
 * Second page of sign up form
 * @author Nadia Mayangputri
 */
const SignUpStep2 = (props) => {
  const { formikProps } = props

  const [uploadedAvatar, setUploadedAvatar] = useState('')
  const [defaultPreviewAvatar, setDefaultPreviewAvatar] = useState(require('../../../assets/blank-profile-picture.png'))
  const [previewAvatar, setPreviewAvatar] = useState()

  useEffect(() => {
    if (formikProps.values.avatar != null) { 
        setPreviewAvatar(formikProps.values.avatar)
    } else {
        setPreviewAvatar(require('../../../assets/blank-profile-picture.png'))
    }
  }, [formikProps, setPreviewAvatar])


  const handleFileUpload = (event) => {
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onloadend = () => setPreviewAvatar(reader.result)
      reader.readAsDataURL(file);
      setUploadedAvatar(file)
  }


  return (
    <>
        <Card style={{ width: 400, maxWidth: '100%', marginBottom: '20px' }}>
            <Card.Meta
            avatar={<div className="avatar-preview" style={{backgroundImage: `url(${previewAvatar})`}}></div>}
            title={<label>Profile image</label>}
            description={
                <div className="d-flex mt-3 mb-3" style={{width:"100%"}}>
                    <>
                    <label htmlFor="file-upload" className="btn purple-btn text-xs m-0">
                        Choose File
                    </label>
                    
                    <input hidden id="file-upload" name="avatar" type="file" accept="image/png, image/jpeg, image/jpg" onChange={(event) => {
                        formikProps.setFieldValue("avatar", event.target.files[0]);
                        handleFileUpload(event)
                    }} />
                    </>

                    <>
                    <label htmlFor="delete-file" className="btn text-xs m-0 ml-2">
                        Delete
                    </label>
                    
                    <input hidden id="delete-file" type="button" onClick={(event) => {
                        formikProps.setFieldValue("avatar", defaultPreviewAvatar);
                        setPreviewAvatar(defaultPreviewAvatar)
                    }} />
                    </>
                </div>   
            }
            />
        </Card>

        {/* <label className="d-block w-100 text-center">Profile image</label>
        <div className="avatar-preview" style={{backgroundImage: `url(${previewAvatar})`}}></div>
        
        <div className="flex-centered mt-3 mb-3" style={{width:"100%"}}>
            <>
            <label htmlFor="file-upload" className="btn blue-btn text-xs m-0">
                Choose File
            </label>
            
            <input hidden id="file-upload" name="avatar" type="file" accept="image/png, image/jpeg, image/jpg" onChange={(event) => {
                formikProps.setFieldValue("avatar", event.target.files[0]);
                handleFileUpload(event)
            }} />
            </>

            <>
            <label htmlFor="delete-file" className="btn pink-btn text-xs m-0 ml-2">
                Delete
            </label>
            
            <input hidden id="delete-file" type="button" onClick={(event) => {
                formikProps.setFieldValue("avatar", defaultPreviewAvatar);
                setPreviewAvatar(defaultPreviewAvatar)
            }} />
            </>
        </div>    */}

        <label>Name</label>
        <Row>
            <Col xs="12" md="6">
                <FormGroup>
                    <Field
                        type="text"
                        label="Given name"
                        name="givenName"
                        id="givenName">
                        {({ field, form: { isSubmitting } }) => (
                            <Input {...field} 
                            disabled={isSubmitting} 
                            type="text"
                            placeholder="Given name"/>
                        )}

                    </Field>
                    {/* {formikProps.errors.givenName && formikProps.touched.givenName ? (
                    <div className="error-msg"><p>{formikProps.errors.givenName}</p></div>
                    ) : null} */}
                    <ErrorMessage name="givenName" component="span" />
                </FormGroup>
            </Col>

            <Col xs="12" md="6">
                <FormGroup>
                    <Field
                        type="text"
                        label="Surname"
                        name="surname"
                        id="surname">
                    {({ field, form: { isSubmitting } }) => (
                    <Input {...field} 
                    disabled={isSubmitting} 
                    type="text" 
                    placeholder="Surname"/>
                        )}

                    </Field>
                    {/* {props.errors.surname && props.touched.surname ? (
                    <div className="error-msg"><p>{props.errors.surname}</p></div>
                    ) : null} */}
                    <ErrorMessage name="surname" component="span" />
                </FormGroup>
            </Col>
        </Row> 
        <Row>
            <Col xs="12">
                <FormGroup>
                    <label>Stage name / Professional name</label>
                    <Field
                        type="text"
                        label="Nickname"
                        name="nickname"
                        id="nickname">
                        {({ field, form: { isSubmitting } }) => (
                        <Input {...field} 
                        disabled={isSubmitting} 
                        type="text" 
                        placeholder="Nickname"/>
                        )}
                    </Field>
                    <span className="help-text">If you are credited under a name that is different to your legal name, please enter your current professional name here</span>

                </FormGroup>
            </Col>
        </Row>
        <Row>
        <Col>
            <FormGroup>
                <Field
                    name="nicknameAsDisplayName"
                    type="checkbox">
                    {({ field, form: { isSubmitting } }) => (
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                        {...field}
                        disabled={isSubmitting}
                        className="custom-control-input"
                        name="nicknameAsDisplayName"
                        type="checkbox"
                        id="nicknameAsDisplayName" 
                        disabled={!(formikProps.values.nickname.length > 0)}/>

                        <label className="custom-control-label checkbox-label font-weight-normal" htmlFor="nicknameAsDisplayName">
                        Set Stage name / Nickname as Display name
                        </label>
                    </div>
                    )}
                </Field>
            </FormGroup>
        </Col>
        </Row>
        <Row>
                <Col xs="12">
                    <FormGroup>
                        <label>Headline</label>
                        <Field
                            type="text"
                            label="Headline"
                            name="headline"
                            id="headline">
                            {({ field, form: { isSubmitting } }) => (
                            <Input {...field} 
                            disabled={isSubmitting} 
                            type="text" 
                            placeholder="Headline"/>
                            )}
                        </Field>
                        <span className="help-text">A short sentence to describe you as a talent (e.g Voice actor and Dubbing Director)</span>

                    </FormGroup>
                </Col>
            </Row>
    </>
  );
};

export default SignUpStep2;
